import React, { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Tooltip from './Tooltip'

TooltipChooseExperience.defaultProps = {
  isVisible: false,
  onSelectNoSpeech: null,
  onSelectWithSpeech: null,
};

function TooltipChooseExperience(props) {
  const {
    isVisible,
    onSelectNoSpeech,
    onSelectWithSpeech,
  } = props;

  return (
    <Tooltip isVisible={isVisible} className="chooseexperience">
      <div className="title">
        Want to use your voice?
      </div>
      <div className="buttons split-2">
        <button onClick={onSelectWithSpeech}>
          Yes
        </button>
        <button onClick={onSelectNoSpeech}>
          No
        </button>
      </div>
      <div className="arrow"></div>
    </Tooltip>
  );
}
export default inject('curbsidega')(observer(TooltipChooseExperience))
