import React from 'react'
import { Animated } from 'react-animated-css'

class SelectorSubMenu extends React.Component {
  state = {}

  render = () => (
    <div
      className='selector-sub-menu'
      style={this.props.open ? selectorContainerOpen : selectorContainerClosed}>
        {this.props.children}
    </div>
  )
}

const selectorContainerOpen = {
  width: '60px',
  borderRadius: '10px',
  backgroundColor: '#666666DA',
  position: 'absolute',
  bottom: '5px',
  left: '7px',
  transform: 'translateY(0)',
  transition: 'all 300ms ease-in-out',
  padding: '10px 2px 70px',
  overflow: 'hidden',
  pointerEvents: 'auto',
  textAlign: 'center',
}
const selectorContainerClosed = {
  ...selectorContainerOpen,
  pointerEvents: 'none',
  transform: 'translateY(10px)',
  opacity: 0,
}

export default SelectorSubMenu
