import React from 'react'
import { ButtonBase } from '@material-ui/core'

import '../../../pages/GamePages.scss'

export default class BasketballLanding extends React.Component {
  state = {
    mounted: false,
  }

  componentDidMount () {
    this.setState({mounted: true})
    if (localStorage.getItem('dev')) {
      setTimeout(() => {
        this.props.action('landing')
      }, 2000)
    }
  }

  continue = () => {
    this.props.action('landing')
  }

  render = () => {
    return (
      <div className={`landing-screen ${this.state.mounted ? 'mounted' : ''}`} onClick={() => { this.props.action('landing') }}>
        <div className='content'>
          {this.props.loaded && (
            <div className='copy'>
              <h1>Nothin’ <span>But Net</span></h1>
              <p>Shoot unlimited hoops before time runs out.</p>
              <ButtonBase onClick={this.continue} className="button">Start</ButtonBase>
            </div>
          )}
          {!this.props.loaded && (
            <div className='copy'>
              <p><strong>Loading...</strong></p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
