import React from 'react'
import styled from 'styled-components'

import ImageBg from '../../assets/images/hboprop/bg.jpg'

export default class ErrorScreenRotate extends React.Component {

  render = () => (
    <Container className='error-screen-camera'>
      <Title>Whoops!</Title>
      <Copy>This experience requires your device to be in portrait mode</Copy>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('${ImageBg}');
  background-size: cover;
  text-align: center;
  color: #fff;
`
const Title = styled.div`
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 30px;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
`
const Copy = styled.div`
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
`
