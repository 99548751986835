import ARPipeline from './ARPipeline'
import ThreejsPipeline from './ThreejsPipeline'

export default new class {
  load = () => {
    return new Promise(resolve => {
      Promise.all([
        new Promise(resolve => {
          const interval = setInterval(() => {
            if (window.XR && window.XRExtras) {
              clearInterval(interval)
              resolve()
            }
          }, 50)
        })
      ]).then(() => {
        this.initPipeline().then(resolve)
      })
    })
  }

  initPipeline = () => new Promise(resolve => {

    // const imageTargets = [...new Set(TargetGroups.map(group => group.targets).flat())]
    // console.log('Using targets', imageTargets)
    // window.XR.XrController.configure({ imageTargets })
    window.XR.XrController.configure({ disableWorldTracking: localStorage.getItem('dev') === 'true' })
    window.XR.addCameraPipelineModules([  // Add camera pipeline modules.
      window.XR.GlTextureRenderer.pipelineModule(),       // Draws the camera feed.
      ThreejsPipeline,
      window.XR.XrController.pipelineModule(),            // Enables SLAM tracking.
      window.XRExtras.AlmostThere.pipelineModule(),       // Detects unsupported browsers and gives hints.
      window.XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
      window.XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
      window.XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
      ARPipeline
    ])

    window.XR.run({ canvas: document.getElementById('ar-canvas') })
    const check = setInterval(() => {
      if (window.XRStarted) {
        this.running = true
        clearInterval(check)
        resolve()
      }
    }, 50)
  })

}()