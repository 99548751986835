import React from "react";
import StreakBar from "../hud/StreakBar";
import Reposition from "../../../assets/images/RepositionIcon.svg";
import Div100vh from "react-div-100vh";
import "../../../pages/GamePages.scss";

import { inject, observer } from "mobx-react";

@inject("football")
@observer
class HUDScreen extends React.Component {
  resetHandler = () => {
    if (this.props.action) {
      this.props.action("hud", "reset");
    }
  }

  pauseHandler = () => {
    if (this.props.action) {
      this.props.action("hud", "pause");
    }
  }

  close = () => {
    if (this.props.action) {
      this.props.action("hud", "close");
    }
  };

  render() {
    return (
      <Div100vh>
        <div className="hud">
          <div className="hud-header">
            <div className='hud-left'>
              <div className='timer-container'>
                <img alt='' src={require('../../../assets/images/hud/Timer-Icon.svg')} />
                <span>:{this.props.football.formattedTime}</span>
              </div>
              <div className='streak-container'>
                <StreakBar streakVisible={this.props.football.isStreak} streakPercent={this.props.football.streakPercent} />
              </div>
            </div>
            <div className="hud-middle">
              <div className="scorebar">
                <span>{this.props.football.formattedScore}</span>
              </div>
            </div>
            <div className="hud-right button-container">
              <img alt='' src={require('../../../assets/images/hud/Pause-Btn.svg')} onClick={this.pauseHandler} />
            </div>
          </div>
          <div className="reposition-container">
            <img
              className="reposition"
              id="reposition"
              src={Reposition}
              alt=""
              onClick={this.resetHandler}
            />
          </div>
        </div>
      </Div100vh>
    );
  }
}

export default HUDScreen
