import {observable, action} from 'mobx'

class HboPropStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable selectedProp = -1

  @action setSelectedProp = selectedProp => {
    this.selectedProp = selectedProp
  }
}

export default HboPropStore
