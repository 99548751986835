import React from 'react'
import exit from '../../assets/images/fanlens/exit_button.png'
import exitBlue from '../../assets/images/fanlens/exit_button_bl.png'

class ExitButton extends React.Component {
  state = {
    pressed: false,
  }

  render = () => (
    <div
      className='exit-button'
      onTouchStart={() => {this.setState({ pressed: true })}}
      onTouchEnd={() => {this.setState({ pressed: false })}}
      style={this.state.pressed ? exitButtonContainerScaled : exitButtonContainer}>
      <img style={exitButton} src={(this.props.color == 'blue' ? exitBlue : exit)} onClick={this.props.onClick}/>
    </div>
  )
}

const exitButtonContainer = {
  position: 'absolute',
  right: '2px',
  top: '10px',
  opacity: 1,
  transition: 'all 150ms ease-in-out'
}
const exitButtonContainerScaled = {
  ...exitButtonContainer,
  transform: 'scale(.8)',
  opacity: .4,
}
const exitButton = {
  height: 55,
  width: 55,
}


export default ExitButton
