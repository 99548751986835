import React from 'react'
import { Animated } from 'react-animated-css'
import Config from '../../config'

const timeout = 2500
const timeoutDelay = 500

class Header extends React.Component {
  state = {
    words: [`"Dream Team"`, `"For The Win"`, `"Hey Hey Hey"`, `"Can't Stop"`],
    iteration: 0,
    visibility: true
  }

  reroute = () => {
    window.location.href = Config.get('url')
  }

  componentDidMount() {
    this.mountTimeout = setTimeout(() => {
      this.setState({visibility: false})
    }, timeout)
    this.mountInterval = setInterval(() => {
      this.setState({iteration: (this.state.iteration + 1) % this.state.words.length})
      this.setState({visibility: true})
      this.innerTimeout = setTimeout(() => {
        this.setState({visibility: false})
      }, timeout)
    }, timeout + timeoutDelay)
  }

  componentWillUnmount () {
    clearInterval(this.mountInterval)
    clearTimeout(this.mountTimeout)
    clearTimeout(this.innerTimeout)
  }

  render = () => (
    <div className='header' onClick={this.reroute}>
      <div className='arrow'></div>
      <div className="header-container">
        <h2 className="stationary">Find</h2>
      </div>
      <Animated
        animationIn='fadeInUp'
        animationOut='fadeOutUp'
        animationInDuration={350}
        animationOutDuration={300}
        isVisible={this.state.visibility}
      >
        <h2 className="animating">{this.state.words[this.state.iteration]}</h2>
      </Animated>
    </div>
  )
}

export default Header