import { observable, computed, action } from 'mobx'


class ExperienceStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable page = null
  @observable targetFound = false
  @observable loadExperience = null

  @computed get currentPage() {
    return this.page
  }

  @action setPage = page => {
    this.page = page
  }

  @action toggleModal = bool => {
    this.targetFound = bool
  }

  @action setExperience = page => {
    this.loadExperience = page
  }
}

export default ExperienceStore