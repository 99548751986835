import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import './TooltipGetStarted.scss'

import Tooltip from './Tooltip'

TooltipGetStarted.defaultProps = {
  isVisible: false,
};

export default function TooltipGetStarted(props) {
  const {
    isVisible,
  } = props;

  return (
    <Tooltip isVisible={isVisible} className="getstarted">
      <div className="text">
        Tap ​the Google Assistant button ​to start.
      </div>
      <div className="arrow"></div>
    </Tooltip>
  );
}
