import React from 'react'
import Lottie from 'react-lottie'
import { isAndroid } from 'react-device-detect'

import animation from './animation';

class Animation extends React.Component {
  state = {
    animePaused: false,
    height: (window.innerWidth < window.innerHeight && window.innerWidth >= 322) ? 207 : 70
  }

  render = () => (
    <Lottie
      options={{
        animationData: animation,
        autoplay: true
      }}
      height={this.state.height}
      isStopped={this.state.animePaused}
    />
  )
}

export default Animation
