import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { isIOS, osVersion } from "react-device-detect";
import MetaTags from "react-meta-tags";
import Div100vh from "react-div-100vh";
import GTM from "../scripts/GTM";

import ScreenRemote from "../components/curbsidega/ScreenRemote";
import ScreenGoogleAssistantBasic from "../components/curbsidega/ScreenGoogleAssistantBasic";
import ScreenVideo from "../components/curbsidega/ScreenVideo";
import SpeechRecognition from "../components/curbsidega/SpeechRecognition";
import Footer from "../components/curbsidega/Footer";
import ErrorScreenRotate from "../components/curbsidega/ErrorScreenRotate";

const VOICE_COMMANDS = [
  {
    id: 0,
    screen: "movies",
    text: "Launch Netflix",
    speechIntent: "netflix",
    videoPath: "/assets/videos/curbsidega/movies.mp4",
    videoWidth: 720,
    videoHeight: 1052,
    videoEndDurationOffset: -1,
    videoLegal:
      "NETFLIX streaming membership required. Sequences shortened and simulated.",
  },
  {
    id: 1,
    screen: "music",
    text: "Play some music",
    speechIntent: "music",
    videoPath: "/assets/videos/curbsidega/music.mp4",
    videoWidth: 720,
    videoHeight: 1052,
    videoEndDurationOffset: -1,
    videoLegal: "Sequences shortened and simulated.",
  },
  {
    id: 2,
    screen: "weather",
    text: "Will it rain tomorrow?",
    speechIntent: "weather",
    videoPath: "/assets/videos/curbsidega/weather.mp4",
    videoWidth: 720,
    videoHeight: 1052,
    videoEndDurationOffset: -1,
    videoLegal: "Sequences shortened and simulated.",
  },
  {
    id: 3,
    screen: "lights",
    text: "Dim the lights",
    speechIntent: "dim",
    videoPath: "/assets/videos/curbsidega/lights.mp4",
    videoWidth: 720,
    videoHeight: 1052,
    videoEndDurationOffset: -1,
    videoLegal:
      "Sequences shortened and simulated. Requires compatible device.",
  },
  {
    id: 4,
    screen: "hbomax",
    text: "Open HBO Max",
    speechIntent: "hbomax",
    videoPath: "/assets/videos/curbsidega/hbomax.mp4",
    videoWidth: 720,
    videoHeight: 1052,
    videoEndDurationOffset: -1,
    videoLegal:
      "HBO Max streaming membership required. Sequences shortened and simulated.",
  },
  {
    id: 5,
    screen: "comedies",
    text: "Show me comedies",
    speechIntent: "comedies",
    videoPath: "/assets/videos/curbsidega/comedies.mp4",
    videoWidth: 720,
    videoHeight: 1052,
    videoEndDurationOffset: -1,
    videoLegal: "Sequences shortened and simulated.",
  },
  {
    id: 6,
    screen: "cartoonnetwork",
    text: "Go to Cartoon Network",
    speechIntent: "cartoonnetwork",
    videoPath: "/assets/videos/curbsidega/cartoonnetwork.mp4",
    videoWidth: 720,
    videoHeight: 1052,
    videoEndDurationOffset: -1,
    videoLegal:
      "Cartoon Network streaming membership required. Sequences shortened and simulated.",
  },
];

function CurbsideGaExperience(props) {
  const { curbsidega } = props;

  const [rotate, setRotate] = useState(
    window.innerHeight < window.innerWidth ? true : false
  );
  const [speechRecognitionInitialize, setSpeechRecognitionInitialize] =
    useState(false);
  const [screenVideoIsVisible, setScreenVideoIsVisible] = useState(false);
  const [isGoogleAssistantVisible, setGoogleAssistantVisible] = useState(false);
  const videoElements = useRef([]);
  const videoAssistantAnimationElements = useRef([]);
  const timeoutIdNoIntent = useRef(null);

  const onOrientationChange = () => {
    if (window.orientation === 90 || window.orientation === -90) {
      setRotate(true);
    } else {
      setRotate(false);
    }
  };

  const disableIOSZooming = () => {
    if (isIOS && parseInt(osVersion) >= 10) {
      try {
        document.addEventListener(
          "touchmove",
          function (event) {
            if (event.scale !== 1) {
              event.preventDefault();
            }
          },
          { passive: false }
        );
      } catch (err) {}
    }
  };

  const onSelectExperience = (speechEnabled) => {
    // Make sure iOS knows we are interacting with the videos
    for (let i = 0; i < videoElements.current.length; i++) {
      videoElements.current[i].play();
      videoElements.current[i].pause();
    }
    for (let i = 0; i < videoAssistantAnimationElements.current.length; i++) {
      videoAssistantAnimationElements.current[i].play();
      videoAssistantAnimationElements.current[i].pause();
    }

    if (speechEnabled) {
      // Initialize speech recognition
      setSpeechRecognitionInitialize(true);

      // Reset the flag after a short delay
      setTimeout(() => {
        setSpeechRecognitionInitialize(false);
      }, 100);
    }
  };

  const onSelectVoiceCommand = (vc) => {
    GTM.trackEvent(vc.text, "Voice Command Selected");
    curbsidega.setSelectedVoiceCommand(vc);
    setGoogleAssistantVisible(true);
    gotoSelectedVoiceCommand();
  };

  const gotoSelectedVoiceCommand = () => {
    if (curbsidega.selectedVoiceCommand) {
      curbsidega.setScreen(curbsidega.selectedVoiceCommand.screen);
      curbsidega.addVoiceCommandSeen(curbsidega.selectedVoiceCommand.id);
      setScreenVideoIsVisible(true);
    }
  };

  const onSpeechRecognitionInitSuccess = () => {
    console.log("onSpeechRecognitionInitSuccess");
    if (curbsidega.setSpeechRecognitionActive) {
      curbsidega.setSpeechRecognitionActive(false);
      setTimeout(() => {
        curbsidega.setSpeechRecognitionActive(true);
      }, 50);
    } else {
      curbsidega.setSpeechRecognitionActive(true);
    }
  };

  const onSpeechRecognitionInitFailure = (err) => {
    console.log("onSpeechRecognitionInitFailure: ", err.message);
    curbsidega.setSpeechRecognitionActive(false);
    curbsidega.setShowTooltipChooseExperience(true);
  };

  const onSpeechRecognitionIntent = (intent) => {
    console.log("onSpeechRecognitionIntent: ", intent);

    cancelSpeechRecognitionIntentTimeout();

    for (let i = 0; i < VOICE_COMMANDS.length; i++) {
      if (VOICE_COMMANDS[i].speechIntent == intent) {
        // Set the selected voice command
        curbsidega.setSelectedVoiceCommand(VOICE_COMMANDS[i]);
        return;
      }
    }
    curbsidega.setSelectedVoiceCommand(false);
    curbsidega.setShowTooltipSpeechCommands(false);
    curbsidega.setShowTooltipSpeechInstructions(true);

    setTimeout(() => {
      curbsidega.setSelectedVoiceCommand(null);
    }, 300);
  };


  const cancelSpeechRecognitionIntentTimeout = () => {
    if (timeoutIdNoIntent.current !== null) {
      clearTimeout(timeoutIdNoIntent.current);
      timeoutIdNoIntent.current = null;
    }
  };

  useEffect(() => {
    console.log("[CurbsideGaExperience] mount");
    let arCanvas = document.getElementById("ar-canvas");
    arCanvas.style.display = "none";

    // Tracking
    GTM.trackPageView("AT&T TV Quick Tour with Google Assistant");

    // Listeners
    window.addEventListener("orientationchange", onOrientationChange);

    // Disable iOS zooming
    disableIOSZooming();

    return () => {
      console.log("[CurbsideGaExperience] unmount");

      // Listeners
      window.removeEventListener("orientationchange", onOrientationChange);
    };
  }, []);

  return (
    <Div100vh>
      <Container className="curbsidega-experience">
        <MetaTags>
          <title>AT&T TV</title>
          <meta name="description" content="" />
          <meta property="og:title" content="AT&T TV" />
          <meta property="og:description" content="" />
        </MetaTags>

        <SpeechRecognition
          initialize={speechRecognitionInitialize}
          onInitSuccess={onSpeechRecognitionInitSuccess}
          onInitFailure={onSpeechRecognitionInitFailure}
          onIntent={onSpeechRecognitionIntent}
        />
        <ScreenRemote
          isVisible={curbsidega.screen == "remote"}
          voiceCommands={VOICE_COMMANDS}
          onSelectExperience={onSelectExperience}
          onSelectVoiceCommand={onSelectVoiceCommand}
        />
        <ScreenGoogleAssistantBasic
          isVisible={isGoogleAssistantVisible}
          text={
            curbsidega.selectedVoiceCommand
              ? curbsidega.selectedVoiceCommand.text
              : ""
          }
          onDone={() => setGoogleAssistantVisible(false)}
        />
        <ScreenVideo
          isVisible={screenVideoIsVisible}
          voiceCommands={VOICE_COMMANDS}
          voiceCommandScreen={curbsidega.screen}
          videoElements={(els) => {
            videoElements.current = els;
          }}
          onDone={() => {
            setScreenVideoIsVisible(false);
            curbsidega.setSelectedVoiceCommand(null);
            curbsidega.setScreen("remote");
          }}
        />
        <Footer
          learnMoreText="Learn more about DIRECTV STREAM"
          showRemoteBackButton={
            curbsidega.screen !== "remote" &&
            !curbsidega.speechRecognitionActive
          }
          onClickRemoteBackButton={() => {
            curbsidega.setSelectedVoiceCommand(null);
            curbsidega.setScreen("remote");
          }}
          showRemoteSpeechButton={false}
          disableRemoteSpeechButton={
            curbsidega.screen !== "remote" &&
            curbsidega.speechRecognitionActive &&
            curbsidega.selectedVoiceCommand != null
          }
          onClickRemoteSpeechButton={() => null}
          onPressRemoteSpeechButton={() => null}
          onReleaseRemoteSpeechButton={() => null}
        />
      </Container>

      {rotate && <ErrorScreenRotate />}
    </Div100vh>
  );
}
export default inject("curbsidega")(observer(CurbsideGaExperience));

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #000;
  overflow: hidden;
`;
