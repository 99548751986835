import { observable, computed, action } from "mobx";

const INITIAL_TIME = 60;
const TIME_VALUE = 5;
const POINT_VALUE = 100;
const STREAK_TOTAL = 5;

class FootballStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.targetReset = function() {};
  }

  @observable score = 0;
  @observable highscore = 0;

  @observable time = INITIAL_TIME;
  @observable streak = 0;
  @observable isStreak = false;
  @observable isPaused = true;

  @computed get streakPercent() {
    return this.streak / STREAK_TOTAL;
  }

  @computed get formattedTime() {
    var time = Math.ceil(this.time);
    return time > 9 ? time : `0${time}`;
  }

  @computed get formattedScore() {
    return this.score.toLocaleString("en");
  }

  @computed get formattedHighscore() {
    return this.highscore.toLocaleString("en");
  }

  @action checkHighscore() {
    this.highscore =
      localStorage.getItem("football_score") !== null
        ? parseInt(localStorage.getItem("football_score"))
        : 0;
    if (this.score > this.highscore) {
      this.highscore = this.score;
      localStorage.setItem("football_score", this.score);
    }
  }

  @action setView = view => {
    this.view = view;
  };

  @action addPoints = tier => {
    var score = scoreValue(tier, this.isStreak);
    this.score += score;
    return score;
  };

  @action addTime = () => {
    this.time += TIME_VALUE;
  };

  @action decrementTime = dt => {
    this.time -= dt;
    if (this.time <= 0) {
      this.time = 0;
    }
  };

  @action reset = () => {
    this.score = 0;
    this.streak = 0;
    this.time = INITIAL_TIME;
    this.isStreak = false;
    this.targetReset();
  };

  @action incrementStreak = () => {
    this.streak += 1;
    if (this.streak >= STREAK_TOTAL) {
      this.streak = STREAK_TOTAL;
      this.isStreak = true;
    }
  };

  @action removeStreak = () => {
    this.streak = 0;
    this.isStreak = false;
  };

  @action setIsPaused = isPaused => {
    this.isPaused = isPaused;
  };
}

function scoreValue(tier, isStreak) {
  var score = POINT_VALUE * (tier + 1);
  return isStreak ? score * 2 : score;
}

export default FootballStore;
