import React from "react"
import { TweenLite, Linear } from 'gsap'

export default class TrackMap extends React.Component {

  constructor () {
    super()

    this.track = React.createRef()
    this.triangle = React.createRef()
    this.shadow = React.createRef()
  }

  componentDidMount() {
    let path = this.track.current
    this.pathLength = path.getTotalLength()
    this.lastPoint = path.getPointAtLength(this.pathLength * (1 - this.props.percent))
    this.lastRotation = 0
    this.offset = this.getInitialOffset()
    this.updateMarker()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.percent !== this.props.percent) {
      this.updateMarker(true)
    }
  }

  updateMarker = (animate = false) => {
    let path = this.track.current
    if(path){
      let time = animate ? .3 : 0
      let point = path.getPointAtLength(this.pathLength * (1 - this.props.percent))
      let newX = point.x + this.offset.x
      let newY= point.y + this.offset.y
      let rotation = Math.atan2(this.lastPoint.y - point.y, this.lastPoint.x - point.x) * 180 / Math.PI
      TweenLite.to(this.triangle.current, time, {x: newX, y: newY, rotation: rotation, transformOrigin:"center", ease: Linear.easeNone})
      TweenLite.to(this.shadow.current, time, {x: newX + 5, y: newY + 5, rotation: rotation, transformOrigin:"center", ease: Linear.easeNone})
      this.lastRotation = rotation
      this.lastPoint = point
    }
  }

  getInitialOffset() {
    let bounds = this.triangle.current.getBBox()
    let x = -(bounds.width / 2)
    let y = -(bounds.height / 2)
    return {x, y}
  }


  render () {
    return (
      <svg viewBox="-10 -10 237.6 152.5">
        <defs>
          <linearGradient id="linear-gradient" x1="108.26" y1="115.8" x2="108.26" y2="0.95" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#010101"/>
            <stop offset="0.2" stopColor="#343434"/>
            <stop offset="0.5" stopColor="#858585"/>
            <stop offset="0.83" stopColor="#2b2b2b"/>
            <stop offset="1" stopColor="#010101"/>
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="111.84" y1="16.4" x2="144.64" y2="16.4" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#e9605b"/>
            <stop offset="1" stopColor="#f9a81a"/>
          </linearGradient>
        </defs>
        <g>
          <rect id="Track-Shadow" x="17.6" y="23.9" width="200.5" height="99.85" rx="49.9" style={{"fill": "#010101", "opacity": 0.1 }}/>
          <rect id="Track-Background" x="8" y="8.5" width="200.5" height="99.85" rx="49.9" style={{"fill": "#fff" }}/>
          <path id="Track" d="M158.6,115.8H57.9A57.4,57.4,0,0,1,57.9,1H158.6a57.4,57.4,0,1,1,0,114.8ZM57.9,15.9a42.5,42.5,0,0,0,0,84.9H158.6a42.5,42.5,0,0,0,0-84.9Z" style={{"fill": "url(#linear-gradient)"}}/>
          <polygon id="Arrow-Background" ref={this.shadow} points="32.8 32.8 0 16.4 32.8 0 32.8 32.8" style={{"fill": "#010101", "opacity": 0.1 }}/>
          <polygon id="Arrow" ref={this.triangle} points="32.8 32.8 0 16.4 32.8 0 32.8 32.8" style={{"fill": "url(#linear-gradient-2)"}}/>
          <path id="Track-Path" ref={this.track} d="M108.3,8.5h50.3a49.8,49.8,0,0,1,49.9,49.9h0a49.8,49.8,0,0,1-49.9,49.9H57.9A49.9,49.9,0,0,1,8,58.4H8A49.9,49.9,0,0,1,57.9,8.5h50.4" style={{'fill': 'none'}}/>
        </g>
      </svg>)
  }
}



