import React from 'react'
import styled from 'styled-components'

import IconArrowDown from '../../assets/images/hboprop/arrow_down.png'

class ButtonPropSelect extends React.Component {
  state = {
    pressed: false,
  }

  render() {
    const {
      onClick,
      selectedProp,
    } = this.props;

    return (
      <Container
        className='prop-select'
        onClick={onClick}
      >
        <ContainerInner>
          {selectedProp &&
            <>
              <ImageIcon src={selectedProp.smallImage} />
              <ImageArrow src={IconArrowDown} />
            </>
          }
        </ContainerInner>
      </Container>
    )
  }
}

const Container = styled.div`
  position: absolute;
  height: 110px;
  width: 110px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  overflow: hidden;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
`
const ContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const ImageIcon = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 100%;
  max-height: 70%;
  transform: translateX(-50%);
`
const ImageArrow = styled.img`
  position: absolute;
  bottom: 12px;
  left: 50%;
  width: 20px;
  height: auto;
  transform: translateX(-50%);
`

export default ButtonPropSelect
