import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import ImageRemoteButton from '../../assets/images/curbsidega/remote-button.png'

Footer.defaultProps = {
  learnMoreText: "Placeholder",
  showRemoteBackButton: false,
  onClickRemoteBackButton: null,
  showRemoteSpeechButton: false,
  disableRemoteSpeechButton: false,
  onClickRemoteSpeechButton: null,
  onPressRemoteSpeechButton: null,
  onReleaseRemoteSpeechButton: null,
};

export default function Footer(props) {
  const {
    learnMoreText,
    showRemoteBackButton,
    onClickRemoteBackButton,
    showRemoteSpeechButton,
    disableRemoteSpeechButton,
    onClickRemoteSpeechButton,
    onPressRemoteSpeechButton,
    onReleaseRemoteSpeechButton,
  } = props;

  const [animateIn, setAnimateIn] = useState(false);

  const onClickLearnMore = () => {
    window.location.href = "/directvstream/";
  }

  useEffect(() => {
    setTimeout(() => {
      setAnimateIn(true);
    }, 3000);
  }, []);

  return (
    <Container style={{
      ...(animateIn ? styleContainerVisible : {}),
      ...(showRemoteBackButton || showRemoteSpeechButton ? styleContainerShowingRemoteButton : {}),
    }}>
      <RemoteButtonBarContainer>
        <RemoteButtonBar style={{
          ...(showRemoteSpeechButton ? styleContainerVisible : {}),
        }}>
          <RemoteButton onClick={onClickRemoteSpeechButton}
            style={{
              ...(disableRemoteSpeechButton ? styleDisabled : {}),
            }}
            //onMouseDown={onPressRemoteSpeechButton}
            onMouseUp={onReleaseRemoteSpeechButton}
            onMouseOut={onReleaseRemoteSpeechButton}
            onTouchStart={onPressRemoteSpeechButton}
            onTouchEnd={onReleaseRemoteSpeechButton}
            onTouchCancel={onReleaseRemoteSpeechButton}
          />
        </RemoteButtonBar>
        <RemoteButtonBar style={{
          ...(showRemoteBackButton ? styleContainerVisible : {}),
        }}>
          <TextRemoteButton>Back to</TextRemoteButton>
          <RemoteButton onClick={onClickRemoteBackButton} />
          <TextRemoteButton>remote</TextRemoteButton>
        </RemoteButtonBar>
      </RemoteButtonBarContainer>

      <LearnMoreContainer onClick={onClickLearnMore}>
        <TextLearnMore>{learnMoreText}</TextLearnMore>
      </LearnMoreContainer>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 145px;
  padding-top: 30px;
  box-sizing: border-box;
  text-align: center;
  background: linear-gradient(transparent 0px, black 30px);
  background-repeat: no-repeat;
  background-position: 0px 53px;
  transform: translateY(115px);
  transition: transform 800ms ease-in-out,
    background-position 800ms ease-in-out;
  pointer-events: none;
`
const styleContainerVisible = {
  transform: 'translateY(0px)',
}
const styleContainerShowingRemoteButton = {
  backgroundPosition: '0px 0px',
}

const RemoteButtonBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 68px;
`
const RemoteButtonBar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 5px + 30px;
  padding-right: 2px;
  box-sizing: border-box;
  text-align: center;
  transform: translateY(115px);
  transition: transform 800ms ease-in-out;
`
const TextRemoteButton = styled.div`
  position: relative;
  font-size: 14px;
  color: #919191;
`
const RemoteButton = styled.button`
  width: 42px;
  height: 42px;
  padding: 0;
  margin: 0 12px;
  background: none;
  background-image: url("${ImageRemoteButton}");
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  pointer-events: all;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
`
const styleDisabled = {
  opacity: '0.25',
}

const LearnMoreContainer = styled.div`
  position: relative;
  width: 100%;
  height: 47px;
  pointer-events: all;
  background: linear-gradient(transparent 0px, black 30px);
`
const TextLearnMore = styled.div`
  position: relative;
  padding: 16px 0;
  font-size: 11px;
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: .5px;
`
