import React from "react";
import styled from "styled-components";
import ExperienceLoader from "../scripts/ExperienceLoader";
import Instructions from "./gameplay/football/Instructions";
import LandingScreen from "./gameplay/football/LandingScreen";
import GamePausedScreen from "./gameplay/GamePausedScreen";
import GameOverScreen from "./gameplay/GameOverScreen";
import HUDScreen from "./gameplay/football/HUDScreen";
import RotateScreen from '../components/gameplay/RotateScreen'

import * as Hammer from "hammerjs";

import Config from "../config";

import { inject, observer } from "mobx-react";
import { observe } from 'mobx'

@inject("football")
@observer
class FootballGame extends React.Component {
  state = {
    view: 'landing',
    loaded: false,
    rotate: false,
  };

  initHammer = () => {
    this.hammer = new Hammer(this.container);
    this.hammer
      .get("pan")
      .set({ direction: Hammer.DIRECTION_ALL, threshold: 0, pointers: 0 });
    this.hammer.on('pan', e => {
      if (e.target === this.hitarea) {
        this.isPanning = true
        this.game.footballGame.onPan(this.getHammerCoordinates(e))
      }
    })
    this.hammer.on('panend', e => {
      if (this.isPanning) {
        this.game.footballGame.onRelease(e)
        this.isPanning = false
      }
    })
  };

  getHammerCoordinates = e => {
    const bounds = this.hitarea.getBoundingClientRect();
    return {
      x: ((e.center.x - bounds.left) / bounds.width) * 2 - 1,
      y: 1 - ((e.center.y - bounds.top) / bounds.height) * 2
    };
  };

  componentDidMount = () => {
    ExperienceLoader.load("Football").then(this.onLoadModule);
    if (localStorage.getItem("dev")) {
      this.gameAction("landing");
    }

    this.setOrientation()

    this.disableStateChange = observe(this.props.football, ({newValue, oldValue, name}) => {
      if(name === 'time'){
        if(newValue <= 0 && this.state.view === 'game'){
          this.gameAction('game', 'gameover')
        }
      }
    })
  }

  setOrientation = () => {
    if (window.innerHeight < window.innerWidth) {
      this.setMustRotate()
    }
    window.addEventListener('orientationchange', () => {
      window.orientation === 90 || window.orientation === -90 ? this.setMustRotate() : this.unsetMustRotate()
    }, false)
  }

  setMustRotate = () => {
    this.setState({ rotate: true })
    if (this.state.view === 'game') {
      this.game.pauseGame()
    }
  }

  unsetMustRotate = () => {
    this.setState({ rotate: false })
    if (this.state.view === 'game') {
      this.game.resumeGame()
    }
  }

  componentWillUnmount = () => {
    this.disableStateChange()
  }

  onLoadModule = module => {
    this.game = new module.default();

    this.game.load().then(() => {
      this.initHammer();
      this.game.init(document.getElementById("ar-canvas"), this.hitarea)
      this.game.addActionCallback(this.gameAction)
      this.setState({ loaded: true });
    })
  }

  closeHandler() {
    window.location.href = Config.get("url") + "unlimited";
  }

  gameAction = (screen, action = null) => {
    if (this.state.loaded) {
      console.log('Game Action', screen, action)
      if (screen === "landing") {
        this.setState({
          view: 'instructions',
        })
      }
      
      if (screen === "instructions") {
        this.setState({
          view: 'game',
        })
        this.game.startGame()
      }

      if (screen === 'game' && action === 'gameover') {
        this.game.endGame()
        this.props.football.checkHighscore()
        this.setState({
          view: 'gameover',
        })
      }

      if (screen === 'paused') {
        if (action === "restart") {
          this.setState({
            view: 'game',
          })
          this.game.restartGame()
        }
        if (action === "help") {
          this.setState({
            view: 'help',
          })
        }
        if (action === "quit") {
          window.location.href = Config.get('url') + 'prepaid'
        }
        if (action === "cancel") {
           this.setState({
            view: 'game',
          })
          this.game.resumeGame()
        }
      }

      if (screen === "hud") {
        if (action === "pause") {
          this.setState({
            view: 'pause',
          })
          this.game.pauseGame()
        }
        if (action === "reset") {
          this.game.recenter();
        }
        if (action === "close") {
          this.closeHandler();
        }
      }

      if (screen === 'gameover') {
        if (action === "restart") {
          this.setState({
            view: 'game',
          })
          this.game.restartGame()
        }
        if (action === "quit") {
          window.location.href = Config.get('url') + 'prepaid'
        }
      }
    }
  };

  render () {
    let GameStore = this.props.football
    
    return (<Container ref={r => (this.container = r)} className="football-game">
      <div
        className={`overlay
        ${this.state.view !== 'game' ? "" : "hide"}
      `}
      ></div>
      { this.state.rotate === false && this.state.view === 'landing' && <LandingScreen loaded={this.state.loaded} action={this.gameAction} /> }
      { this.state.rotate === false && (this.state.view === 'instructions' || this.state.view === 'help') && <Instructions loaded={this.state.loaded} showCloseButton={this.state.view === 'help'} action={this.gameAction} /> }
      { this.state.rotate === false && this.state.view === 'pause' && <GamePausedScreen action={this.gameAction} /> }
      { this.state.rotate === false && this.state.view === 'gameover' && <GameOverScreen score={GameStore.score} highscore={GameStore.highscore} scoreText={GameStore.formattedScore} highscoreText={GameStore.formattedHighscore} action={this.gameAction} /> }
      { this.state.rotate === false && this.state.view === 'game' && !this.props.football.isPaused && <HUDScreen action={this.gameAction} /> }
      { this.state.rotate === true && <RotateScreen action={this.gameAction} /> }
      <HitArea ref={r => (this.hitarea = r)}></HitArea>
    </Container>)
  }
}

export default FootballGame

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const HitArea = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
