import React from 'react'
import './LoadingSpinner.css';

import ImageSpinner from '../../assets/images/hboprop/spinner.svg'

class LoadingSpinner extends React.Component {
  state = {}
  render = () => {
    const { isVisible } = this.props;

    return (
      <img src={ImageSpinner} className='ios-loading-spinner' />
    )
  }
}
export default LoadingSpinner
