import ExperienceStore from 'state/ExperienceStore'
import RacingStore from 'state/RacingStore'
import FootballStore from 'state/FootballStore'
import BasketballStore from 'state/BasketballStore'
import FanLensStore from 'state/FanLensStore'
import HboPropStore from 'state/HboPropStore'
import CurbsideGaStore from 'state/CurbsideGaStore'

class RootStore {
  constructor() {
    this.experience = new ExperienceStore(this)
    this.racing = new RacingStore(this)
    this.football = new FootballStore(this)
    this.basketball = new BasketballStore(this)
    this.fanlens = new FanLensStore(this)
    this.hboprop = new HboPropStore(this)
    this.curbsidega = new CurbsideGaStore(this)
  }
}

export default new RootStore()
