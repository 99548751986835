import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

class ButtonRound extends React.Component {
  state = {}
  render = () => {
    const {
      style,
      onClick,
      children,
      href,
      download,
    } = this.props

    return (
      <Button className="button"
        style={{...styleButton, ...style}}
        onClick={onClick}
        href={href}
        download={download}
      >
        {children}
      </Button>
    )
  }
}

const styleButton = {
  display: 'block',
  position: 'relative',
  minWidth: '195px',
  height: 'auto',
  padding: '19px 0',
  margin: '0',
  color: '#0057b8',
  fontSize: '18px',
  fontFamily: 'ATT Aleck Sans, sans-serif',
  fontWeight: '500',
  textTransform: 'none',
  lineHeight: '1.2',
  backgroundColor: '#fff',
  boxShadow: '0 9px 9px 0 rgba(0, 0, 0, 0.05)'
}

export default ButtonRound
