import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { ButtonBase, Button } from '@material-ui/core'
import { isIOS, osVersion, mobileModel } from 'react-device-detect';

class DownloadOverlay extends React.Component {
  state = {}
  render = () => {
    let hasSave = true;
    if (isIOS && parseInt(osVersion) <= 12) {
      hasSave = false;
    }

    return (
      <Container className='download-overlay'>
        {(this.props.loading && this.props.imageProcessResult == null) &&
          <Animated style={imageContainer} animationIn="fadeIn" duration="750" isVisible={true}>
            <Image style={imagePixelated} src={this.props.pixelatedImage}/>
          </Animated>
        }
        {this.props.loading &&
          <Animated style={processingContainer} animationIn="fadeIn" isVisible={true}>
            <Text style={processing}>Processing...</Text>
          </Animated>
        }
        {(!this.props.loading && this.props.imageProcessResult == false) &&
          <div style={imageContainer}>
            <Image style={imagePixelated} src={this.props.pixelatedImage}/>
            <Animated animationIn="fadeIn" isVisible={true}>
              <div style={imageFailed}></div>
            </Animated>
          </div>
        }
        {(!this.props.loading && this.props.imageProcessResult == false) &&
          <Animated animationIn="fadeIn" isVisible={true} style={failedTextContainer}>
            <Text style={textLgWhite}>That One<br/>Didn’t Work</Text>
            <Text style={textMdWhite}>Let’s Try Again</Text>
          </Animated>
        }
        {(!this.props.loading && this.props.imageProcessResult == true) &&
          <div style={imageContainer}>
            <Image style={imagePixelated} src={this.props.pixelatedImage}/>
            <Animated animationIn="fadeIn" isVisible={true}>
              <Image style={imageSuccess} src={this.props.fullImage}/>
            </Animated>
          </div>
        }

        {(!this.props.loading && this.props.imageProcessResult == true && hasSave) &&
          <Animated style={saveButtonContainer} animationIn="fadeInUp" isVisible={true}>
            <ButtonBase style={saveButton} className='button' href={this.props.fullImage} download="fanframe.jpg">Save</ButtonBase>
          </Animated>
        }
        {(!this.props.loading && this.props.imageProcessResult == true && !hasSave) &&
          <Animated style={window.innerWidth < 375 ? saveInstructionsContainer_SmallScreen : saveInstructionsContainer} animationIn="fadeInUp" isVisible={true}>
            <Text style={saveInstructions}>Tap and hold to save.</Text>
          </Animated>
        }

        {(!this.props.loading) &&
          <Animated style={window.innerWidth < 375 ? retakeButtonContainer_SmallScreen : retakeButtonContainer} animationIn="fadeInUp" isVisible={true}>
            <Button style={retakeButton} onClick={this.props.onRestart}>Retake</Button>
          </Animated>
        }
      </Container>
    )
  }
}

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const Text = styled.div`
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
`
const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`
const imageContainer = {
  position: 'absolute',
  top: '40px',
  width: '73%',
  height: 'auto',
  padding: '5px 5px 0px',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 15px 5px #B2B2B2',
  transform: 'rotate(-2deg)',
  textAlign: 'center',
}
const imagePixelated = {
  opacity: .75,
}
const imageSuccess = {
  position: 'absolute',
  top: '5px',
  left: '5px',
  height: 'calc(100% - 12px)',
  width: 'calc(100% - 9px)',
}
const imageFailed = {
  position: 'absolute',
  top: '5px',
  height: 'calc(100% - 9.5px)',
  width: 'calc(100% - 10px)',
  background: 'linear-gradient(to bottom, #1169b1, #26a9e5)',
}
const processingContainer = {
  position: 'absolute',
  marginTop: '-40px',
}
const processing = {
  color: '#1064ad',
}
const failedTextContainer = {
  position: 'absolute',
  marginTop: '-30px',
}
const textLgWhite = {
  position: 'relative',
  color: '#fff',
  lineHeight: 1.15,
}
const textMdWhite = {
  position: 'relative',
  fontSize: '17px',
  color: '#fff',
  marginTop: '20px',
}

const saveButtonContainer = {
  position: 'absolute',
  bottom: '60px'
}
const saveButton = {
  padding: '0 11vw',
  height: '52px',
  fontSize: '16px',
}

const retakeButtonContainer = {
  position: 'absolute',
  bottom: '20px',
}
const retakeButtonContainer_SmallScreen = {
  ...retakeButtonContainer,
  bottom: '10px',
}
const retakeButton = {
  fontFamily: '"ATT Aleck Sans", sans-serif',
  fontSize: '16px',
  fontWeight: 900,
  color: '#1064ad'
}

const saveInstructionsContainer = {
  position: 'absolute',
  bottom: '70px',
  left: 0,
  right: 0,
  margin: '0 auto',
}
const saveInstructionsContainer_SmallScreen = {
  ...saveInstructionsContainer,
  bottom: '47px',
}
const saveInstructions = {
  textAlign: 'center',
  color: '#000',
  fontSize: '16px',
  fontWeight: 500,
  textTransform: 'none',
  textShadow: '0 0 5px white',
}

export default DownloadOverlay
