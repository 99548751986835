import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import ExperienceLoader from '../scripts/ExperienceLoader'

// import './LandingExperience.scss'

import Header from './landing-experience/Header'
import TargetFoundCta from './landing-experience/TargetFoundCta'
import CameraTargetIndicator from '../assets/images/CameraTargetIndicator.svg'

import GTM from '../scripts/GTM'

@inject('experience')
@observer
class Interaction extends React.Component {

  render () {
    return (
      <div>
        <Header />
        <img id='target-indicator' src={CameraTargetIndicator} alt='' />
          <TargetFoundCta
            id={this.props.experience.page}
            target={this.props.experience.targetFound}
            click={() => {
              this.props.click()
            }}
          />
      </div>
    )
  }
}

@inject('experience', 'racing')
@observer
class LandingGame extends React.Component {
  state = {
    loaded: false,
    experience: 'None',
    modalActive: false,
  }

  componentDidMount = () => {
    ExperienceLoader.load('Landing').then(this.onLoadModule)
  }

  componentWillUnmount() {
    if (this.game) {
      this.game.destroy()
      this.game = null
    }
  }

  onLoadModule = module => {
    this.game = new module.default()
    this.game.load().then(this.onExperienceLoad)
  }

  onExperienceLoad = () => {
    this.setState({loaded: true}, this.onInit)
  }

  markerUpdate({name}) {
    if (this.props.experience.targetFound === true || this.game === null) return

    if (name === null) {
      //marker removed
      this.setState({
        experience: 'None'
      })
    } else {
      this.acceptHandler()
    }
  }

  acceptHandler() {
    if (this.props.experience.targetFound === true) return
    
    let marker = this.game.activeMarker.trim()
    let view = ''
    let endUrl = ''

    if (/^racing_1/.test(marker)) {
      view = 'racing'
      endUrl = 'fiber'
    }
    if (/^racing_2/.test(marker)) {
      view = 'racing'
      endUrl= 'internet'
    }

    if (/^football_/.test(marker)) {
      view = 'football'
    }

    if (/^basketball_/.test(marker)) {
      view = 'basketball'
    }
    
    this.props.experience.setPage(view)
    this.props.racing.setEndUrl(endUrl)
    
    // open modal
    this.props.experience.toggleModal(true)
    this.setState({
      experience: view,
    })

    // GTM - marker was found
    GTM.trackEvent(view, 'Marker Found')
  }
  
  targetFoundClick() {
    this.props.change(this.state.experience)
    // GTM - page opened
    GTM.trackPageView(this.state.experience)
  }

  closeHandler() {
    //just cloes the window
  }

  onInit = () => {
    this.game.init(document.getElementById('ar-canvas'))
    this.game.on('markerUpdate', this.markerUpdate.bind(this))
  }

  getDebugText () {
    return `Hi ${this.props.experience.targetFound}:${this.state.experience}`
  }

  render() {
    return (
      <Container ref={r => (this.container = r)} className='landing-game'>
        <Interaction type={this.state.experience} click={() => {this.targetFoundClick()}} />
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`


export default LandingGame