import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { isIOS, osVersion, mobileModel } from 'react-device-detect';
import GTM from '../../scripts/GTM'

import Overlay from './Overlay'
import ButtonRound from './ButtonRound'
import ButtonNoBackground from './ButtonNoBackground'
import ImageBg from '../../assets/images/hboprop/bg.jpg'

class OverlayFinal extends React.Component {
  state = {
    downloaded: false,
  }

  _onPressDownload(e) {
    GTM.trackEvent("Download Button", "Download Photo")
    this.setState({ downloaded: true });
  }
  _onPressTakeAnother(e) {
    GTM.trackEvent("Take Another Button", "Take Another")
    if (this.props.onRestart) {
      this.props.onRestart();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible) {
      if (this.props.isVisible) {
        this.setState({ downloaded: false });
      }
    }
  }
  render() {
    const {
      isVisible,
      imageSrc,
      validationResult,
      onRestart,
      onRevalidate,
    } = this.props;
    const {
      downloaded,
    } = this.state;

    let hasSave = true;
    if (isIOS && parseInt(osVersion) <= 12) {
      hasSave = false;
    }

    return (
      <Overlay isVisible={isVisible}>
        <Container className='overlay-download'>
          <ImageContainer>
            {imageSrc &&
              <Image src={imageSrc}
                style={{ opacity: (validationResult ? 1 : 0)}} />
            }
            {validationResult == false &&
              <ErrorContainer>
                <ErrorTitle>Oh no!</ErrorTitle>
                <ErrorCopy>That one didn't take.</ErrorCopy>
              </ErrorContainer>
            }
          </ImageContainer>

          <BottomContainer>
            {validationResult == true &&
              <>
                {hasSave &&
                  <>
                    <Animated animationIn="fadeInUp" isVisible={true}>
                      <ButtonRound href={imageSrc} onClick={(e) => this._onPressDownload(e)} download="hbo-selfie.jpg" style={styleFullWidth}>
                        {(downloaded ? 'Downloaded': 'Download')}
                      </ButtonRound>
                    </Animated>
                    <DownloadedText style={{ height: (downloaded ? 'auto' : 0)}}>
                      <strong>Saved!</strong> If on iOS, check your Files app.
                    </DownloadedText>
                  </>
                }
                {!hasSave &&
                  <Animated  animationIn="fadeInUp" isVisible={true}>
                    <TextHoldToSave>
                      Tap and hold to save.
                    </TextHoldToSave>
                  </Animated>
                }
                <Animated  animationIn="fadeInUp" isVisible={true}>
                  <ButtonNoBackground onClick={(e) => this._onPressTakeAnother(e)} style={styleFullWidth}>
                    Take Another
                  </ButtonNoBackground>
                </Animated>
              </>
            }
            {validationResult == false &&
              <>
                <Animated animationIn="fadeInUp" isVisible={true}>
                  <ButtonRound onClick={onRevalidate} style={styleFullWidth}>
                    Try Again
                  </ButtonRound>
                </Animated>
                <Animated  animationIn="fadeInUp" isVisible={true}>
                  <ButtonNoBackground onClick={onRestart} style={styleFullWidth}>
                    Exit to HBO Max Selfie
                  </ButtonNoBackground>
                </Animated>
              </>
            }

          </BottomContainer>
        </Container>
      </Overlay>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('${ImageBg}');
  background-size: cover;
`
const ImageContainer = styled.div`
  position: relative;
  padding: 58px 40px 19px;
`
const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`
const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`
const TextHoldToSave = styled.div`
  font-size: 28px;
  font-weight: 900;
  text-align: center;
`

const DownloadedText = styled.div`
  margin-top: 5px;
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
  text-align: center;
  overflow: hidden;
`
const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
`
const ErrorTitle = styled.div`
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 30px;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
`
const ErrorCopy = styled.div`
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
`

const styleFullWidth = {
  width: '100%',
}

export default OverlayFinal
