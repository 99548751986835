import React from 'react'
import { observer, inject } from 'mobx-react'
import TrackMap from "../hud/TrackMap"
import Reposition from '../../../assets/images/RepostionCamera.png'
import Arrows from '../../../assets/images/SledGameArrows.png'

import '../../../pages/GamePages.scss'


@inject('racing')
@observer
class HUDScreen extends React.Component {

  resetHandler = () => {
    if (this.props.action) {
      this.props.action('hud', 'reset')
    }
  }
  
  pauseHandler = () => {
    if (this.props.action) {
      this.props.action('hud', 'pause')
    }
  }

  render () {
    const state = this.props.racing

    return (
      <div className='hud horizontal-hud'>
        <div className="hud-header">
          <div className='hud-left'>
            <div className='track-container'>
              <div className="track-map">
                <div className='track-score'>
                  <span>{state.scoreText}</span>
                  <img alt='' src={require('../../../assets/images/hud/FT-Icon.svg')} />
                </div>
                <TrackMap percent={state.progressPercent} />
              </div>
            </div>
            <div className='timer-container'>
              <img alt='' src={require('../../../assets/images/hud/Timer-Icon.svg')} />
              <span>:{state.timeText}</span>
            </div>
          </div>
          <div className="hud-right button-container">
            <img alt='' src={require('../../../assets/images/hud/Pause-Btn.svg')} onClick={this.pauseHandler} />
          </div>
        </div>
        <div className='reposition-container'>
          <img className="reposition" id='reposition'  src={require('../../../assets/images/RepositionIcon.svg')} alt='' onClick={this.resetHandler} />
        </div>
        <div className="gaming-arrows">
          <img className="arrows up" src={Arrows}/>
          <img className="arrows down" src={Arrows}/>
        </div>
      </div>
    )
  }
}
export default HUDScreen
