import React from "react";
import { ButtonBase } from "@material-ui/core";
import Reposition from "../../../assets/images/RepostionCamera.png";
import "../../../pages/GamePages.scss";

export default class FootballLanding extends React.Component {
  state = {
    mounted: false
  };

  componentDidMount() {
    this.setState({ mounted: true });
    if (localStorage.getItem("dev")) {
      setTimeout(() => {
        this.props.action("landing");
      }, 2000);
    }
  }

  continue = () => {
    if (window.XR || window.XR.XrController) {
      window.XR.XrController.recenter();
    }
    this.props.action("landing");
  };

  render = () => {
    return (
      <div
        className={`landing-screen football ${this.state.mounted ? "mounted" : ""}`}
        onClick={() => {
          this.props.action("landing");
        }}
      >
        <div className="content">
          {this.props.loaded && (
            <div className="copy">
              <h1>Pass <br /> <span>Zone</span></h1>
              <p>Hit targets for points to show you’re in control.</p>
              <ButtonBase onClick={this.continue} className="button">Start</ButtonBase>
            </div>
          )}
          {!this.props.loaded && (
            <div className="copy">
              <p>
                <strong>Loading...</strong>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };
}
