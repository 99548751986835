import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const DURATION = 300;

export default function Overlay(props) {
  const {
    isVisible,
    children,
  } = props;

  const [visible, setVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setFadeIn(true);
      }, 50)
    }
  }, [visible]);

  useEffect(() => {
    if (!fadeIn) {
      setTimeout(() => {
        setVisible(false);
      }, 300)
    }
  }, [fadeIn]);

  useEffect(() => {
    if (isVisible) {
      setVisible(true);
    }
    else {
      setFadeIn(false);
    }
  }, [isVisible]);


  let styles = {};
  if (visible) {
    styles = {...styles, ...styleVisible};
  }
  if (fadeIn) {
    styles = {...styles, ...styleFadeIn};
  }

  return (
    <OverlayContainer style={styles}>
      <OverlayContent>
        {children}
      </OverlayContent>
    </OverlayContainer>
  );
}

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
`
const OverlayContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const styleVisible = {
  display: 'block',
}
const styleFadeIn = {
  opacity: '1',
}
