import React from 'react'
import { ButtonBase } from '@material-ui/core'
import Flick from '../../../assets/images/FlickIcon.svg'
import Streak from '../../../assets/images/StreakIcon.svg'
import Health from '../../../assets/images/HealthIcon.svg'
import Reposition from '../../../assets/images/RepositionIcon.svg'
import CloseButton from '../CloseButton'

// Slick Carousel
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class NextButton extends React.Component {
  enterGame = () => {
    this.props.click('instructions', 'game')
  }

  enterPause = () => {
    this.props.click('instructions', 'pause')
  }

  play = () => {
    this.props.click('instructions', 'play')
  }

  render() {
    const {currentSlide, slideCount, onClick} = this.props
    const lastSlide = currentSlide === slideCount - 1

    const finalWording = this.props.showCloseButton ? 'Continue' : "Play"
    const finalFunction = this.props.showCloseButton ? this.enterGame : this.enterPause

    return (
      <div className="button-container">
        <ButtonBase className="button" onClick={ lastSlide ? finalFunction : onClick } >
          { lastSlide ? finalWording : 'Next' }
        </ButtonBase>
        <span className={`${lastSlide ? 'hide' : 'skip-button'}`} onClick={ this.play }>SKIP</span> 
      </div>
    )
  }
}

class Instructions extends React.Component {
  state = {
    mounted: false,
  }

  settings = {
    dots: true,
    arrows: true,
    autplay: false,
    infinite: true,
    nextArrow: <NextButton click={() => { this.play() }} />
  }

  close = () => {
    this.props.action('instructions', 'close')
  }

  play = () => {
    if (this.props.action) {
      this.props.action('instructions', 'play')
    }
  }

  componentDidMount () {
    this.setState({mounted: true})
    console.log(this.props)
  }

  render = () => (
    <div className={`instructions ${this.state.mounted ? 'mounted' : ''}`}>
      <Slider {...this.settings}>
        <div className='instruction-screen'>
          <div className='image-container flick'>
            <img src={Flick} alt='' />
          </div>
          <p>Swipe up to throw the ball and score big.</p>
        </div>

        <div className='instruction-screen'>
          <div className='image-container'>
            <img src={Streak} alt='' />
          </div>
          <p>Three completed passes in a row starts a bonus streak!</p>
        </div>

        <div className='instruction-screen'>
          <div className='image-container health'>
            <img className='' src={Health} alt='' />
          </div>
          <p>Look for time bonuses.</p>
        </div>

        <div className='instruction-screen'>
          <div className='image-container'>
            <img src={Reposition} alt='' />
          </div>
          <p>Tap this if you lose sight of the targets.</p>
        </div>
      </Slider>
      { !this.props.showCloseButton ? '' : <CloseButton click={() => { this.close() }} />}
    </div>
  )
}

export default Instructions