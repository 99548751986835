import React from 'react'
import '../../../pages/GamePages.scss'
import { ButtonBase } from '@material-ui/core'

export default class SnowballLanding extends React.Component {
  state = {
    mounted: false,
  }

  componentDidMount () {
    this.setState({mounted: true})
  }
  render = () => {
    return (
      <div className={`landing-screen ${this.state.mounted ? 'mounted' : ''}`} onClick={() => { this.props.action('landing') }}>
        <div className='content'>
          <h1>Internet <br /><span>Speedway</span></h1>
          {this.props.loaded && (
            <div className='copy'>
              <p>Race at top speeds for the win as America’s favorite.</p>
              <ButtonBase className="button">Next</ButtonBase>
            </div>
          )}
          {!this.props.loaded && (
            <div className='copy'>
              <p>
                <strong>Loading...</strong>
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
