import {observable, action} from 'mobx'

const STICKER_COUNT = 6;

class FanLensStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable color1 = 10
  @observable color2 = 5
  @observable sticker = Math.floor((Math.random()*STICKER_COUNT)+1)

  @action setColor1 = color1 => {
    this.color1 = color1
  }
  @action setColor2 = color2 => {
    this.color2 = color2
  }
  @action setSticker = sticker => {
    this.sticker = sticker
  }
}

export default FanLensStore
