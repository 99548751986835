import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import Screen from './Screen'

ScreenGoogleAssistantBasic.defaultProps = {
  isVisible: false,
  text: "Placeholder",
  onDone: null,
};

export default function ScreenGoogleAssistantBasic(props) {
  const {
    isVisible,
    text,
    onDone,
  } = props;

  const [displayNumWords, setDisplayNumWords] = useState(0);
  const [fadeInLastWord, setFadeInLastWord] = useState(false);
  const timeoutIdStartWords = useRef(null);
  const timeoutIdWord = useRef(null);
  const timeoutIdDone = useRef(null);
  const timeoutIdReset = useRef(null);

  const addWord = (num, separation) => {
    const numWords = text.split(" ").length;

    if (num <= numWords) {
      setFadeInLastWord(false);
      setDisplayNumWords(num);

      setTimeout(() => {
        setFadeInLastWord(true);
      }, 20);

      timeoutIdWord.current = setTimeout(() => {
        timeoutIdWord.current = null;
        addWord(num+1, separation);
      }, separation);
    }
  }
  const getText = () => {
    let txts = [];

    // Open quote
    if (displayNumWords > 0) {
      txts.push(<Text key="lq" style={{opacity:1}}>“</Text>);
    }

    // Words
    let words = text.split(" ");
    for (var i = 0; i < displayNumWords; i++) {
      let str = words[i];
      if (i > 0)
        str = " "+str;

      if (i == displayNumWords - 1) { // Last word
        txts.push(<Text key={"w"+i} style={{opacity:(fadeInLastWord ? 1 : 0)}}>
          {str}
        </Text>);
      }
      else {
        txts.push(<Text key={"w"+i} style={{opacity:1}}>
          {str}
        </Text>);
      }
    }

    // Close quote
    if (displayNumWords > 0) {
      txts.push(<Text key="rq" style={{opacity:1}}>”</Text>);
    }

    return txts;
  }
  const start = () => {
    const numWords = text.split(" ").length;
    const startDelay = 600;
    const wordSeperation = 300;
    const endDelay = 1000;

    timeoutIdStartWords.current = setTimeout(() => {
      timeoutIdStartWords.current = null;
      addWord(1, wordSeperation);
    }, startDelay);

    timeoutIdDone.current = setTimeout(() => {
      timeoutIdDone.current = null;
      onDone();
    }, startDelay + (numWords * wordSeperation) + endDelay);
  }
  const clearTimeouts = () => {
    if (timeoutIdStartWords.current !== null) {
      clearTimeout(timeoutIdStartWords.current);
      timeoutIdStartWords.current = null;
    }
    if (timeoutIdWord.current !== null) {
      clearTimeout(timeoutIdWord.current);
      timeoutIdWord.current = null;
    }
    if (timeoutIdDone.current !== null) {
      clearTimeout(timeoutIdDone.current);
      timeoutIdDone.current = null;
    }
    if (timeoutIdReset.current !== null) {
      clearTimeout(timeoutIdReset.current);
      timeoutIdReset.current = null;
    }
  }

  useEffect(() => {
    if (isVisible) {
      start();
    }
    else { // Not visible
      clearTimeouts();

      timeoutIdReset.current = setTimeout(() => {
        setDisplayNumWords(0);
        setFadeInLastWord(false);
      }, 500);
    }
  }, [isVisible]);

  useEffect(() => {
    return () => {
      clearTimeouts();
    }
  }, []);

  return (
    <Screen isVisible={isVisible}>
      <Container>
        <TextContainer>
          {getText()}
        </TextContainer>
      </Container>
    </Screen>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 115px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  zIndex: 1000;
`
const TextContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`
const Text = styled.span`
  font-size: 18px;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
`
