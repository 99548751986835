import React from 'react'
import styled from 'styled-components'

class MenuPropSelect extends React.Component {
  state = {}

  render() {
    const {
      active,
      onSelect,
      selectedProp,
      propsList,
    } = this.props;

    return (
      <Container className='menu-prop-select'
        style={(active ? styleContainerActive : {})}
      >
        <ContainerProps>
          {propsList.map((prop, index) => (
            <Prop key={'prop-'+index} onClick={() => { onSelect(index) }}>
              <PropImage style={{ backgroundImage: 'url('+prop.smallImage+')' }}>
                <PropImageShadow style={{
                  backgroundImage: 'url('+prop.smallGlowImage+')',
                  opacity: (selectedProp == index ? '1' : '0'),
                }} />
              </PropImage>
              <PropLabel style={(selectedProp == index ? stylePropLabelSelected : {})}>
                {prop.showName}
              </PropLabel>
            </Prop>
          ))}
        </ContainerProps>
        <Title>Choose your Prop</Title>
      </Container>
    )
  }
}

const MENU_TOP_GAP = '30px';

const Container = styled.div`
  position: absolute;
  top: ${MENU_TOP_GAP};
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  transform: translateY(100vh);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  overflow: hidden;
  background-color: rgba(255,255,255,0.7);
  backdrop-filter: blur(3px);
`
const styleContainerActive = {
  transform: 'translateY(0)',
  opacity: 1,
}
const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 11px 0 25px;
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  color: #9a35ef;
  background: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1) 66%, rgba(255,255,255,0) 100%)
`
const ContainerProps = styled.div`
  position: relative;
  display: flex;
  padding: 55px 10px 100px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`
const Prop = styled.div`
  position: relative;
  margin: 0 10px 35px 10px;
  width: 120px;
  height: auto;
`
const PropImage = styled.div`
  position: relative;
  display: block;
  width: 120px;
  height: 120px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`
const PropImageShadow = styled.div`
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  display: block;
  width: 175px;
  height: 175px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px;
  transform: translate(-50%,-50%);
  transition: opacity 300ms ease-in-out;
`
const PropLabel = styled.div`
  position: relative;
  width: 100%;
  margin-top: 4px;
  font-size: 14px;
  text-align: center;
  color: #000;
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: normal;
  font-style: italic;
`
const stylePropLabelSelected = {
  color: '#9a35ef',
  fontWeight: 'bold',
  fontStyle: 'italic',
}

export default MenuPropSelect
