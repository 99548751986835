import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { inject, observer } from 'mobx-react'
import { isIOS, osVersion, isSafari } from 'react-device-detect';
import MetaTags from 'react-meta-tags';
import ExperienceLoader from '../scripts/ExperienceLoader'
import Div100vh from 'react-div-100vh'
import GTM from '../scripts/GTM'

import ErrorScreenCamera from '../components/hboprop/ErrorScreenCamera'
import ErrorScreenRotate from '../components/hboprop/ErrorScreenRotate'
import ErrorScreenUnsupportedBrowser from '../components/hboprop/ErrorScreenUnsupportedBrowser'

import ButtonSwitchCamera from '../components/hboprop/ButtonSwitchCamera'
import ButtonTakePhoto from '../components/hboprop/ButtonTakePhoto'
import ButtonPropSelect from '../components/hboprop/ButtonPropSelect'
import ButtonExit from '../components/hboprop/ButtonExit'
import MenuPropSelect from '../components/hboprop/MenuPropSelect'
import ButtonRetake from '../components/hboprop/ButtonRetake'
import ButtonRound from '../components/hboprop/ButtonRound'
import OverlayPropInstructions from '../components/hboprop/OverlayPropInstructions'
import OverlayProcessing from '../components/hboprop/OverlayProcessing'
import OverlayFinal from '../components/hboprop/OverlayFinal'

import ImageBg from '../assets/images/hboprop/bg.jpg'

@inject('hboprop')
@observer
class HboPropExperience extends React.Component {
  state = {
    ready: false,
    rotate: (window.innerHeight < window.innerWidth),
    browserSupported: (!isIOS || (isIOS && isSafari)),
    cameraFacing: 'user',
    cameraPermissionError: false,
    propSelectMenuOpen: false,
    cameraImageData: null,
    showOverlayPropInstructions: false,
    showOverlayProcessing: false,
    finalImageData: null,
    validationResult: null, // [null,true,false]
  }

  cameraReady = false
  refCameraVideo = React.createRef()
  refCanvas = React.createRef()

  componentDidMount = () => {
    this.hboprop = null
    let arCanvas = document.getElementById('ar-canvas');
    arCanvas.style.display = "none";

    // Tracking
    GTM.trackPageView('HBO Selfie');

    // If we are on an unsupported browser don't init
    if (!this.state.browserSupported) {
      return;
    }

    // Load the module script
    ExperienceLoader.load('HboProp').then((module) => {
      this.hboprop = new module.default()

      this.hboprop.initCamera(this.refCameraVideo)
        .then(() => {
          this.cameraReady = true;
          this.onReady();
        })
        .catch((e) => {
          this.setState({ cameraPermissionError: true });
          console.log('[HboPropExperience] Camera setup Error: ', e);
          console.log('[HboPropExperience] Camera setup Error Name:', e.name);

          console.log('testing, remove this');
          this.cameraReady = true;
          this.onReady();
        })
      this.hboprop.initCanvas(this.refCanvas);
    })

    // Listeners
    window.addEventListener("resize", this.onResize.bind(this));
    window.addEventListener("orientationchange", this.onOrientationChange.bind(this));

    // Disable iOS zooming
    if (isIOS && parseInt(osVersion) >= 10) {
      try {
        document.addEventListener('touchmove', function (event) {
          if (event.scale !== 1) { event.preventDefault(); }
        }, { passive: false });
      } catch(err) {
      }
    }
  }
  componentWillUnmount = () => {
    // Listeners
    window.removeEventListener("resize", this.onResize.bind(this));
    window.removeEventListener("orientationchange", this.onOrientationChange.bind(this));
  }
  onOrientationChange = () => {
    if (window.orientation === 90 || window.orientation === -90) {
      this.setState({ rotate: true })
    }
    else {
      this.setState({ rotate: false }, this.ready)
    }
  }
  onResize = () => {
    if (this.state.ready) {
      this.hboprop.onResize();
    }
  }
  onReady = () => {
    if (this.cameraReady) {
      this.setState({
        ready: true,
        propSelectMenuOpen: true,
      });
    }
  }

  onPropSelectMenuToggle = () => {
    this.setState({
      propSelectMenuOpen: !this.state.propSelectMenuOpen
    })
  }
  onPropSelect = (index) => {
    console.log('[HboPropExperience] onPropSelect: ', index);
    const store = this.props.hboprop
    store.setSelectedProp(index);

    let prop = this.hboprop.getProp(index);
    if (prop) {
      GTM.trackEvent(prop.showName, "Prop Selected")
    }

    this.hboprop.setSelectedProp(index);

    setTimeout(() => {
      this.setState({ propSelectMenuOpen: false });
    }, 500);
  }
  onSwitchCamera = () => {
    if (this.state.cameraFacing == 'user') {
      GTM.trackEvent("World-facing", "Camera Selected")

      this.setState({ cameraFacing: 'environment' })
      this.hboprop.switchCamera('environment')
    }
    else {
      GTM.trackEvent("Selfie", "Camera Selected")

      this.setState({ cameraFacing: 'user' })
      this.hboprop.switchCamera('user')
    }
  }
  onTakePhoto = () => {
    const store = this.props.hboprop
    console.log('[HboPropExperience] onTakePhoto');

    GTM.trackEvent("Take Photo Button", "Take Photo")

    this.hboprop.takeSnapshot()
      .then((cameraImageData) => {
        console.log("HboPropExperience] Camera captured: ", cameraImageData)
        this.setState({
          cameraImageData: cameraImageData,
          showOverlayPropInstructions: true,
        })

        setTimeout(() => {
          this.setState({ showOverlayPropInstructions: false })
        }, 2000)
      })
      .catch((e) => {
        console.log("HboPropExperience] Image capture ERROR: ", e)
      })
  }
  onFinishPhoto = () => {
    this.setState({ showOverlayProcessing: true });

    this.hboprop.createFinalImage()
      .then((imageData) => {
        console.log("FanLensExperience] Final image: ", imageData)
        this.setState({
          finalImageData: imageData
        })

        this.hboprop.validateImage(imageData)
          .then((json) => {
            console.log("FanLensExperience] Validated ", json)
            this.setState({
              showOverlayProcessing: false,
              validationResult: json.ok
            })
          })
          .catch((e) => {
            console.log("FanLensExperience] Validation ERROR: ", e)
          })
      })
      .catch((e) => {
        console.log("FanLensExperience] Final image ERROR: ", e)
      })
  }
  onRevalidate = () => {
    console.log('[HboPropExperience] onRevalidate');
    this.setState({
      showOverlayProcessing: true,
      validationResult: null,
    });

    this.hboprop.validateImage(this.state.finalImageData)
      .then((json) => {
        console.log("FanLensExperience] Validated ", json)
        this.setState({
          showOverlayProcessing: false,
          validationResult: json.ok
        })
      })
      .catch((e) => {
        console.log("FanLensExperience] Validation ERROR: ", e)
      })
  }
  onResetKeepProp = () => {
    console.log('[HboPropExperience] onResetKeepProp');

    this.setState({
      cameraImageData: null,
      showOverlayPropInstructions: false,
      showOverlayProcessing: false,
      finalImageData: null,
      validationResult: null,
    })
  }
  onRestart = () => {
    console.log('[HboPropExperience] onRestart');
    const store = this.props.hboprop
    store.setSelectedProp(-1);

    this.hboprop.setSelectedProp(-1);

    this.setState({
      propSelectMenuOpen: true,
      cameraImageData: null,
      showOverlayPropInstructions: false,
      showOverlayProcessing: false,
      finalImageData: null,
      validationResult: null,
    })
  }
  onExit = () => {
    GTM.trackEvent("Close 'X' Button", "Exit Action")
    window.location.href = "/hbomax"
  }

  render () {
    const { hboprop } = this.props
    const {
      ready,
      rotate,
      browserSupported,
      cameraFacing,
      cameraPermissionError,
      propSelectMenuOpen,
      cameraImageData,
      showOverlayPropInstructions,
      showOverlayProcessing,
      finalImageData,
      validationResult,
    } = this.state

    if (cameraPermissionError) {
      return <ErrorScreenCamera />;
    }
    if (!browserSupported) {
      return <ErrorScreenUnsupportedBrowser />;
    }

    const showBottomBarTakePhoto = (ready && !cameraImageData ? true : false);
    const showBottomBarRetakeDone = (ready && cameraImageData ? true : false);

    return (
      <Div100vh>
        <Container className='hboprop-experience'>
          <MetaTags>
            <title>HBO Max Selfie</title>
            <meta name="description" content="" />
            <meta property="og:title" content="HBO Max Selfie" />
            <meta property="og:description" content="" />
          </MetaTags>


          <TopContainer>
            <CameraVideo style={(cameraFacing == 'user' ? styleCameraFlipped : {})} ref={r => this.refCameraVideo = r} />

            {cameraImageData &&
              <OverlayImage src={cameraImageData} />
            }
            <OverlayCanvas ref={r => this.refCanvas = r} />
          </TopContainer>
          <BottomContainer>
            {showBottomBarTakePhoto &&
              <>
                <ButtonSwitchCamera onClick={this.onSwitchCamera}/>
                <ButtonTakePhoto onClick={this.onTakePhoto} />
                {this.hboprop &&
                  <ButtonPropSelect
                    onClick={this.onPropSelectMenuToggle}
                    selectedProp={this.hboprop.getProp(hboprop.selectedProp)}
                  />
                }
              </>
            }
            {showBottomBarRetakeDone &&
              <BottomBarHorizontalButtons>
                <ButtonRetake onClick={this.onResetKeepProp} />
                <ButtonRound onClick={this.onFinishPhoto}>
                  Looks Good!
                </ButtonRound>
              </BottomBarHorizontalButtons>
            }
          </BottomContainer>

          <OverlayFinal
            isVisible={(validationResult !== null)}
            imageSrc={finalImageData}
            validationResult={validationResult}
            onRestart={this.onRestart}
            onRevalidate={this.onRevalidate}
          />

          <MenuPropSelect
            active={propSelectMenuOpen}
            onSelect={this.onPropSelect}
            selectedProp={hboprop.selectedProp}
            propsList={(this.hboprop ? this.hboprop.getProps() : [])}
          />

          {ready &&
            <Animated isVisible={(!propSelectMenuOpen)}
              animationIn="fadeIn" animationInDuration={300}
              animationOut="fadeOut" animationOutDuration={300}>
              <ButtonExit onClick={this.onExit} color='white' />
            </Animated>
          }

          <OverlayPropInstructions isVisible={showOverlayPropInstructions} />
          <OverlayProcessing isVisible={showOverlayProcessing} />


        </Container>

        {rotate &&
          <ErrorScreenRotate/>
        }
      </Div100vh>
    )
  }
}

const BOTTOM_CONTAINER_HEIGHT = '114px';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background-image: url('${ImageBg}');
  background-size: cover;
  overflow: hidden;
`
const TopContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - ${BOTTOM_CONTAINER_HEIGHT});
  overflow: hidden;
`
const BottomContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${BOTTOM_CONTAINER_HEIGHT};
`

const CameraVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const styleCameraFlipped = {
  transform: 'rotateY(180deg)',
  WebkitTransform: 'rotateY(180deg)',
  MozTransform: 'rotateY(180deg)',
}
const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const OverlayCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const BottomBarHorizontalButtons = styled.div`
  position: absolute;
  top: 0;
  right: 17px;
  left: 17px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`


const RotateContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default HboPropExperience
