import React, { useState, useRef, useEffect } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Animated } from "react-animated-css";
import "./ScreenRemote.scss";

import Screen from "./Screen";
import TooltipGetStarted from "./TooltipGetStarted";
import TooltipChooseExperience from "./TooltipChooseExperience";
import TooltipSelectVoiceCommand from "./TooltipSelectVoiceCommand";
import GTM from "../../scripts/GTM";

import ImageATTLockup from "../../assets/images/curbsidega/dtv-lockup.png";
import ImageATTLockupWith from "../../assets/images/curbsidega/dtv-lockup-with.png";
import ImageATTLockupGA from "../../assets/images/curbsidega/dtv-lockup-ga.png";
import ImageRemote from "../../assets/images/curbsidega/remote.png";
import ImageRemoteLights from "../../assets/images/curbsidega/remote-lights.png";
import ImageRemoteButtonHighlight from "../../assets/images/curbsidega/remote-button-highlight.png";
import ImageRemoteButtonKnockout from "../../assets/images/curbsidega/remote-button-knockout.png";

ScreenRemote.defaultProps = {
  isVisible: false,
  voiceCommands: [],
  onSelectExperience: null,
  onSelectVoiceCommand: null,
};

function ScreenRemote(props) {
  const {
    isVisible,
    voiceCommands,
    onSelectExperience,
    onSelectVoiceCommand,
    curbsidega,
  } = props;

  const [firstVisible, setFirstVisible] = useState(true);
  const [logoFadeIn, setLogoFadeIn] = useState(false);
  const [logoFadeInSynced, setLogoFadeInSynced] = useState(false);
  const [legalFadeIn, setLegalFadeIn] = useState(false);
  const [logoToTop, setLogoToTop] = useState(false);
  const [remoteToTop, setRemoteToTop] = useState(false);
  const [showRemoteLights, setShowRemoteLights] = useState(false);
  const [animateRemoteButtonHighlight, setAnimateRemoteButtonHighlight] =
    useState(false);
  const intervalIdRemoteButtonHighlight = useRef(null);
  const timeoutIdRemoteButtonHighlight = useRef(null);

  const onClickRemoteButton = (e) => {
    if (e) e.preventDefault();
    GTM.trackEvent("Get started", "Google Assistant Button");

    curbsidega.setShowTooltipGettingStarted(false);
    setShowRemoteLights(true);
    stopHighlight();

    setTimeout(() => {
      // curbsidega.setShowTooltipChooseExperience(true);
      curbsidega.setShowTooltipSelectVoiceCommand(true);
    }, 500);
  };
  // const onSelectExperienceNoSpeech = (e) => {
  //   if (e) e.preventDefault();
  //   GTM.trackEvent("Tap", "Experience Selected");

  //   curbsidega.setShowTooltipChooseExperience(false);
  //   onSelectExperience(false);

  //   setTimeout(() => {
  //     curbsidega.setShowTooltipSelectVoiceCommand(true);
  //   }, 500);
  // }
  // const onSelectExperienceWithSpeech = (e) => {
  //   if (e) e.preventDefault();
  //   GTM.trackEvent("Speech", "Experience Selected");

  //   curbsidega.setShowTooltipChooseExperience(false);
  //   onSelectExperience(true);
  // }
  const _onSelectVoiceCommand = (vc) => {
    setRemoteToTop(false);
    setLogoFadeIn(false);
    setLogoFadeInSynced(false);
    setLegalFadeIn(false);

    curbsidega.setShowTooltipSelectVoiceCommand(false);

    setTimeout(() => {
      onSelectVoiceCommand(vc);
      setAnimateRemoteButtonHighlight(false);
    }, 800);
  };
  const speechActivated = () => {
    setRemoteToTop(false);
    setLogoFadeIn(false);
    setLogoFadeInSynced(false);
    setLegalFadeIn(false);

    setTimeout(() => {
      curbsidega.setScreen("ga-speech");
      setAnimateRemoteButtonHighlight(false);
    }, 800);
  };
  const startHighlight = () => {
    stopHighlight();

    // Once
    setAnimateRemoteButtonHighlight(true);

    timeoutIdRemoteButtonHighlight.current = setTimeout(() => {
      setAnimateRemoteButtonHighlight(false);
    }, 1000);

    // Interval
    intervalIdRemoteButtonHighlight.current = setInterval(() => {
      setAnimateRemoteButtonHighlight(true);

      timeoutIdRemoteButtonHighlight.current = setTimeout(() => {
        setAnimateRemoteButtonHighlight(false);
      }, 1000);
    }, 3000);
  };
  const stopHighlight = () => {
    if (intervalIdRemoteButtonHighlight.current) {
      clearInterval(intervalIdRemoteButtonHighlight.current);
    }
    if (timeoutIdRemoteButtonHighlight.current) {
      clearTimeout(timeoutIdRemoteButtonHighlight.current);
    }
  };

  useEffect(() => {
    // Visible - first time
    if (isVisible && firstVisible) {
      setTimeout(() => {
        setLogoFadeIn(true);
      }, 100);

      setTimeout(() => {
        setLegalFadeIn(true);
        setLogoToTop(true);
        setRemoteToTop(true);

        setTimeout(() => {
          curbsidega.setShowTooltipGettingStarted(true);
        }, 1800);

        setTimeout(() => {
          startHighlight(true);
        }, 3000);
      }, 3000);
    }

    // Visible - subsequent times
    if (isVisible && !firstVisible) {
      setTimeout(() => {
        setLogoFadeInSynced(true);
        setLegalFadeIn(true);
        setRemoteToTop(true);
      }, 100);

      if (curbsidega.showTooltipChooseExperience) {
        curbsidega.setShowTooltipChooseExperience(false);
        setTimeout(() => {
          curbsidega.setShowTooltipChooseExperience(true);
        }, 1500);
      } else {
        setTimeout(() => {
          curbsidega.setShowTooltipSelectVoiceCommand(true);
        }, 1500);
      }
    }

    // Not visible
    if (!isVisible) {
      stopHighlight();
    }

    // Set the first visible flag
    if (isVisible) setFirstVisible(false);
  }, [isVisible]);

  useEffect(() => {
    if (curbsidega.speechRecognitionActive) {
      speechActivated();
    }
  }, [curbsidega.speechRecognitionActive]);

  return (
    <Screen isVisible={isVisible}>
      <div className="screen-remote">
        <div
          className={
            "remote" +
            (remoteToTop ? " to-top" : "") +
            (remoteToTop && window.innerHeight < 618 ? " small" : "")
          }
        >
          <div className="inner">
            <img src={ImageRemote} alt="AT&T TV Remote" />
            <img
              src={ImageRemoteLights}
              alt="AT&T TV Remote Lights"
              style={{
                opacity: showRemoteLights ? 1 : 0,
              }}
            />
            <button
              className={animateRemoteButtonHighlight ? "animate" : ""}
              onClick={onClickRemoteButton}
            >
              <div>
                <img
                  className="bg"
                  src={ImageRemoteButtonKnockout}
                  alt="Remote Google Assistant Button"
                />
                <img
                  className="highlight"
                  src={ImageRemoteButtonHighlight}
                  alt="Remote Google Assistant Button Highlight"
                />
              </div>
            </button>
          </div>
        </div>

        <div
          className={
            "att-lockup" +
            (logoFadeIn ? " fade-in" : "") +
            (logoFadeInSynced ? " fade-in-synced" : "") +
            (logoToTop ? " to-top" : "") +
            (logoToTop && window.innerHeight < 618 ? " small" : "")
          }
        >
          <div className="inner">
            <img
              className="logo"
              src={ImageATTLockup}
              alt="A Quick Tour of AT&T TV with Google Assistant"
            />
            <img
              className="with"
              src={ImageATTLockupWith}
              alt="A Quick Tour of AT&T TV with Google Assistant"
            />
            <img
              className="ga"
              src={ImageATTLockupGA}
              alt="A Quick Tour of AT&T TV with Google Assistant"
            />
          </div>
        </div>

        <div className={"legal google" + (legalFadeIn ? " fade-in" : "")}>
          *Google login required. Google is a trademark of Google LLC.
        </div>
        <div className={"legal simulation" + (legalFadeIn ? " fade-in" : "")}>
          Simulation. Actual product may differ.
        </div>

        <TooltipGetStarted isVisible={curbsidega.showTooltipGettingStarted} />
        {/* <TooltipChooseExperience
          isVisible={curbsidega.showTooltipChooseExperience}
          onSelectNoSpeech={onSelectExperienceNoSpeech}
          onSelectWithSpeech={onSelectExperienceWithSpeech}
        /> */}
        <TooltipSelectVoiceCommand
          isVisible={curbsidega.showTooltipSelectVoiceCommand}
          voiceCommands={voiceCommands}
          onSelectVoiceCommand={_onSelectVoiceCommand}
        />
      </div>
    </Screen>
  );
}
export default inject("curbsidega")(observer(ScreenRemote));
