import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { inject, observer } from 'mobx-react'
import ExperienceLoader from '../scripts/ExperienceLoader'
import Div100vh from 'react-div-100vh'

import RotateScreen from '../components/gameplay/RotateScreen'
import ColorSelector from '../components/fanlens/ColorSelector'
import StickerSelector from '../components/fanlens/StickerSelector'
import TakePhotoButton from '../components/fanlens/TakePhotoButton'
import ExitButton from '../components/fanlens/ExitButton'
import DownloadOverlay from '../components/fanlens/DownloadOverlay'
import CameraErrorScreen from '../components/fanlens/CameraErrorScreen'

import Config from '../config'

@inject('fanlens')
@observer
class FanLensExperience extends React.Component {
  state = {
    ready: false,
    colorSelectorMenuOpen: false,
    stickerSelectorMenuOpen: false,
    showDownloadOverlay: false,
    loading: false,
    imageProcessResult: null, // [null,true,false]
    pixelatedImageData: null,
    fullImageData: null,
    rotate: (window.innerHeight < window.innerWidth),
    cameraPermissionError: false,
  }

  cameraReady = false
  animationsReady = false

  container = React.createRef()
  bgPrimaryAnimationCanvas = React.createRef()
  bgSecondaryAnimationCanvas = React.createRef()
  stickerCanvas = React.createRef()
  cameraVideo = React.createRef()

  componentDidMount = () => {
    this.fanlens = null
    let arCanvas = document.getElementById('ar-canvas');
    arCanvas.style.display = "none";

    // Load the module script
    ExperienceLoader.load('FanLens').then((module) => {
      this.fanlens = new module.default()
      this.setupCamera();
      this.setupAnimations();
    })

    // Listeners
    window.addEventListener("resize", this.onResize.bind(this));
    window.addEventListener("orientationchange", this.onOrientationChange.bind(this));
  }
  componentWillUnmount = () => {
    // Listeners
    window.removeEventListener("resize", this.onResize.bind(this));
    window.removeEventListener("orientationchange", this.onOrientationChange.bind(this));
  }
  onOrientationChange = () => {
    if (window.orientation === 90 || window.orientation === -90) {
      this.setState({ rotate: true })
      this.fanlens.stopAnimations();
    }
    else {
      this.setState({ rotate: false }, this.ready)
    }
  }

  setupCamera = () => {
    this.fanlens.initCamera(this.cameraVideo)
      .then(() => {
        this.cameraReady = true;
        this.ready();
      })
      .catch((e) => {
        this.setState({ cameraPermissionError: true });
        console.log('[FanLensExperience] Camera setup Error: ', e);
        console.log('[FanLensExperience] Camera setup Error Name:', e.name);
      })
  }
  setupAnimations = () => {
    Promise.all([
      this.fanlens.initBackgroundPrimaryAnimation(this.bgPrimaryAnimationCanvas, this.props.fanlens.color1),
      this.fanlens.initBackgroundSecondaryAnimation(this.bgSecondaryAnimationCanvas, this.props.fanlens.color2),
      this.fanlens.initStickerAnimation(this.stickerCanvas),
    ])
      .then((values) => {
        this.animationsReady = true;
        this.ready();
      })
      .catch((e) => {
        console.log('[FanLensExperience] Animation setup error: ', e);
        alert(e);
      })
  }
  ready = () => {
    if (this.cameraReady && this.animationsReady) {
      this.setState({ ready: true });

      if (this.state.rotate) {
        this.fanlens.stopAnimations();
        return;
      }

      setTimeout(() => {
        this.fanlens.startAnimations();

        setTimeout(() => {
          this.fanlens.changeSticker(this.props.fanlens.sticker);
        }, 500)
      }, 250)
    }
  }

  onResize = () => {
    if (this.state.ready) {
      this.fanlens.onResize();
    }
  }

  onColorSelectorMenuToggle = () => {
    this.setState({
      colorSelectorMenuOpen: !this.state.colorSelectorMenuOpen,
      stickerSelectorMenuOpen: false
    })
  }
  onColor1Change = (color) => {
    const store = this.props.fanlens
    console.log('[FanLensExperience] onColor1Change: ', color);
    store.setColor1(color);

    this.fanlens.changeBackgroundPrimaryColor(color);
  }
  onColor2Change = (color) => {
    const store = this.props.fanlens
    console.log('[FanLensExperience] onColor2Change: ', color);
    store.setColor2(color);

    this.fanlens.changeBackgroundSecondaryColor(color);
  }
  onStickerSelectorMenuToggle = () => {
    this.setState({
      stickerSelectorMenuOpen: !this.state.stickerSelectorMenuOpen,
      colorSelectorMenuOpen: false
    })
  }
  onStickerChange = (sticker) => {
    const store = this.props.fanlens
    console.log('[FanLensExperience] onStickerChange: ', sticker);
    store.setSticker(sticker);

    this.fanlens.changeSticker(sticker);
  }
  onTakePhoto = () => {
    const store = this.props.fanlens
    console.log('[FanLensExperience] onTakePhoto');

    this.fanlens.stopAnimations();
    this.setState({ showDownloadOverlay: true, loading: true })

    this.fanlens.takeSnapshot()
      .then(({cameraImageData, fullImageData}) => {
        console.log("FanLensExperience] Camera captured: ", cameraImageData)
        console.log("FanLensExperience] Full image captured: ", fullImageData)
        this.setState({ fullImageData: fullImageData })

        this.fanlens.createPixelatedSnapshot(fullImageData)
          .then((pixelatedImageData) => {
            console.log("FanLensExperience] Pixelated image: ", pixelatedImageData)
            this.setState({ pixelatedImageData: pixelatedImageData })
          })
          .catch((e) => {
            console.log("FanLensExperience] Pixelated image ERROR: ", e)
          })

        this.fanlens.validateCameraImage(cameraImageData)
          .then((json) => {
            console.log("FanLensExperience] Validated ", json)
            this.setState({ loading: false, imageProcessResult: json.ok })
          })
          .catch((e) => {
            console.log("FanLensExperience] Validation ERROR: ", e)
          })
      })
      .catch((e) => {
        console.log("FanLensExperience] Image capture ERROR: ", e)
      })
  }
  onRestart = () => {
    console.log('[FanLensExperience] onRestart');
    this.fanlens.startAnimations();
    this.setState({
      showDownloadOverlay: false,
      loading: false,
      imageProcessResult: null,
      pixelatedImageData: null,
      fullImageData: null,
    })
  }
  onExit = () => {
    window.location.href = "/"
  }

  render () {
    const { fanlens } = this.props

    if (this.state.cameraPermissionError) {
      return <CameraErrorScreen />;
    }

    return (
      <Div100vh>
        <Container ref={r => (this.container = r)} className='fanlens-experience'>

          <CameraVideo ref={r => this.cameraVideo = r} />
          <OverlayCanvas ref={r => this.bgPrimaryAnimationCanvas = r} />
          <OverlayCanvas ref={r => this.bgSecondaryAnimationCanvas = r} />
          <OverlayCanvas ref={r => this.stickerCanvas = r} />

          {(this.state.ready && !this.state.showDownloadOverlay) &&
            <Animated animationIn="fadeIn" duration="750" isVisible={true}>
              <ColorSelector
                menuOpen={this.state.colorSelectorMenuOpen}
                onMenuToggle={this.onColorSelectorMenuToggle}
                color1={fanlens.color1}
                onColor1Change={this.onColor1Change}
                color2={fanlens.color2}
                onColor2Change={this.onColor2Change}
              />
              <StickerSelector
                menuOpen={this.state.stickerSelectorMenuOpen}
                onMenuToggle={this.onStickerSelectorMenuToggle}
                sticker={fanlens.sticker}
                onStickerChange={this.onStickerChange}
              />
              <TakePhotoButton onClick={this.onTakePhoto} />
            </Animated>
          }

          { this.state.showDownloadOverlay &&
            <DownloadOverlay
              loading={this.state.loading}
              imageProcessResult={this.state.imageProcessResult}
              pixelatedImage={this.state.pixelatedImageData}
              fullImage={this.state.fullImageData}
              onRestart={this.onRestart}
            />
          }

          {this.state.ready &&
            <ExitButton onClick={this.onExit} color={(this.state.showDownloadOverlay ? 'blue' : 'white')} />
          }
        </Container>

        {this.state.rotate &&
          <RotateContainer>
            <RotateScreen/>
          </RotateContainer>
        }
      </Div100vh>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
`
const OverlayCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const CameraVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #71CFFF;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
`
const RotateContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default FanLensExperience
