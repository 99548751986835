import Config from "./config";

const production = {
  isLocal: false,
  isProduction: true,
  experience: "",
  url: "/",
};

const dev = {
  ...production,
  isLocal: true,
  isProduction: false
};

// initialize config
Config.default(production)
  .environment(/10.72.4.34:3000/, {...dev, experience: 'football'})
  .environment(/192.168.2.135:3000/, {...dev, experience: 'racing'})
  .environment(/bryandev/, {...dev, experience: 'football'})
  .environment(/jareddev/, {...dev, experience: ''})
  .environment(/savanadev/, {...dev, experience: ''})
  .environment(/fanframe/, {...dev, experience: 'fanframe'})
  .detect()

export default Config;
