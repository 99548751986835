import {observable, action, computed} from 'mobx'

class RacingStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable score = 0
  @observable highscore = 0

  @observable time = 60
  @observable progress = 0
  @observable distance = 0
  @observable endUrl = null

  @action checkHighscore() {
    this.highscore =
      localStorage.getItem('racing_score') !== null
        ? parseInt(localStorage.getItem('racing_score'))
        : 0
    if (this.score > this.highscore) {
      this.highscore = this.score
      localStorage.setItem('racing_score', this.score)
    }
  }

  @computed get highscoreText() {
    return this.score.toLocaleString('en')
  }

  @computed get scoreText() {
    return this.score.toLocaleString('en')
  }

  @computed get timeText() {
    return this.time < 10 ? `0${this.time}` : `${this.time}`
  }

  @computed get progressPercent() {
    return (this.distance % 1).toFixed(2)
  }

  @action setDistance = distance => {
    if (distance > this.distance) this.distance = distance
  }

  @action setEndUrl = url => {
    this.endUrl = url
    console.log('endurl store', this.endUrl)
  }

  @action setScore = score => {
    this.score = score
  }
  @action setTime = time => {
    this.time = time
  }
}

export default RacingStore
