import React from 'react'
import CloseButton from './CloseButton'
import { ButtonBase } from '@material-ui/core'
import '../../pages/GamePages.scss'
import Div100vh from 'react-div-100vh'

export default class GamePaused extends React.Component {
  restart = () => {
    if (this.props.action) {
      this.props.action('paused', 'restart')
    }
  }

  help = () => {
    if (this.props.action) {
      this.props.action('paused', 'help')
    }
  }

  quit = () => {
    if (this.props.action) {
      this.props.action('paused', 'quit')
    }
  }
  
  close = e => {
    this.props.action('paused', 'cancel')
  }

  render () {
    return (
      <Div100vh>
        <div className='game-paused'>
          <CloseButton click={this.close} />
          <div className="content">
            <h1>Game paused</h1>
            <div className='buttons'>
              <ButtonBase className="button" onClick={this.restart}>Restart</ButtonBase>
              <ButtonBase className="button" onClick={this.help}>Help</ButtonBase>
              <ButtonBase className="button" id="end" onClick={this.quit}>Quit</ButtonBase>
            </div>
          </div>
        </div>
      </Div100vh>
    )
  }
}
