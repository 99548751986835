import React from 'react'
import styled from 'styled-components'

import Overlay from './Overlay'
import LoadingSpinner from './LoadingSpinner'

class OverlayProcessing extends React.Component {
  state = {
    message: this._getMessage(),
  }
  _getMessage() {
    let msgIndex = Math.floor( Math.random() * 3 );

    switch (msgIndex) {
      case 0: return 'Capturing the moment...';
      case 1: return 'Checking the film...';
      case 2: return 'Adding final touches...';
    }
    return 'No message';
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible) {
      if (this.props.isVisible) {
        this.setState({ message: this._getMessage() });
      }
    }
  }
  render = () => {
    const { isVisible } = this.props;
    const { message } = this.state;

    return (
      <Overlay isVisible={isVisible}>
        <Container className='overlay-processing'>
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
          <Text>{message}</Text>
        </Container>
      </Overlay>
    )
  }
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255,255,255,0.7);
  backdrop-filter: blur(3px);
`
const SpinnerContainer = styled.div`
  position: relative;
  margin-bottom: 39px;
`
const Text = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #9a35ef;
`

export default OverlayProcessing
