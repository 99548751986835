import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const FADE_DURATION = 600;

Screen.defaultProps = {
  isVisible: false,
  children: [],
};

export default function Screen(props) {
  const {
    isVisible,
    children,
  } = props;

  const [visible, setVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const timeoutId = useRef(null);

  useEffect(() => {
    if (timeoutId.current !== null) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }

    if (isVisible) {
      setVisible(true);
      timeoutId.current = setTimeout(() => {
        setFadeIn(true);
        timeoutId.current = null;
      }, 50);
    }
    else {
      setFadeIn(false);
      timeoutId.current = setTimeout(() => {
        setVisible(false);
        timeoutId.current = null;
      }, FADE_DURATION);
    }
  }, [isVisible]);


  let styles = {};
  if (visible) {
    styles = {...styles, ...styleVisible};
  }
  if (fadeIn) {
    styles = {...styles, ...styleFadeIn};
  }

  return (
    <Container style={styles}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity ${FADE_DURATION}ms ease-in-out;
`
const styleVisible = {
  pointerEvents: 'auto',
}
const styleFadeIn = {
  opacity: '1',
}
