import React from 'react'

export default class CloseButton extends React.Component {

  closeHandler = () => {
    if (this.props.click) {
      this.props.click()
    } else {
      window.location.href = "/end"
    }
  }

  render () {
    return <div onClick={this.closeHandler} className='close'></div>
  }
}