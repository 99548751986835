import React from 'react'
import takePhoto from '../../assets/images/fanlens/take_photo_button.png'

class TakePhotoButton extends React.Component {
  state = {
    pressed: false,
  }

  _getStyles() {
    if(window.innerWidth <= 374)
      return (this.state.pressed ? photoButtonContainerScaledSm : photoButtonContainerSm)
    else
      return (this.state.pressed ? photoButtonContainerScaled : photoButtonContainer)
  }

  render = () => (
    <div
      className='photo-button'
      onClick={this.props.onClick}
      onTouchStart={() => { this.setState({ pressed: true }) }}
      onTouchEnd={() => { this.setState({ pressed: false }) }}
      style={this._getStyles()}>
      <img style={window.innerWidth <= 374 ? photoButtonSm : photoButton} src={takePhoto} />
    </div>
  )
}

const photoButtonContainer = {
  position: 'absolute',
  height: '100px',
  width: '100px',
  left: '50%',
  bottom: '10px',
  transform: 'translateX(-50%) scale(1)',
  opacity: 1,
  transition: 'all 150ms ease-in-out',
  overflow: 'hidden',
}

const photoButtonContainerScaled = {
  ...photoButtonContainer,
  transform: 'translateX(-50%) scale(.9)',
  opacity: .8,
}
const photoButtonContainerScaledSm = {
  ...photoButtonContainerScaled,
  transform: 'translateX(-50%) scale(.65)',
  bottom: '5px',
}
const photoButton = {
  position: 'absolute',
  top: '-5.5px',
  left: '-5.5px',
  height: '111px',
  width: '111px',
  pointerEvents: 'none',
}
const photoButtonSm = {
  ...photoButton,
  top: '-8px',
  left: '-7px'
}

// breakpoint styles
const photoButtonContainerSm = {
  ...photoButtonContainer,
  transform: 'translateX(-50%) scale(.75)',
  bottom: '8px',
}

export default TakePhotoButton
