import React from 'react'
import Logo from '../../assets/images/Logo.png'
import Hoop from '../../assets/images/Hoop.png'
import { ButtonBase } from '@material-ui/core'

const button = false

export default class CameraErrorScreen extends React.Component {

  render = () => (
    <div className='ar-loading-screen error'>
      <img className='logo' src={Logo} alt='logo' />
      <img className='image' src={Hoop} alt='' />
      <h1>Whoops!</h1>
      <p>Refresh the page to enable your camera for AR</p>
      { button && <ButtonBase className="button">Button</ButtonBase> }
    </div>
  )
}