import React from 'react';
import './ErrorScreenDesktop.css'

import ImageLogo from '../../assets/images/hboprop/att-globe.svg';
import ImageQR from '../../assets/images/hboprop/qrcode_hbomax.png';

const ErrorScreenDesktop = (props) => {
  const pageUrl = "https://unlock.att.com/hbomax/";

  return (
    <div className="incompatibility">
      <div className="incompatibility__content">
        <img className="incompatibility__logo" src={ImageLogo} alt="AT&T Globe logo" />

        <h1 className="incompatibility__heading">Get your phone ready.</h1>

        <div className="incompatibility__code">
          <img src={ImageQR} alt="QR code" />
        </div>

        <p className="incompatibility__copy">
          To get the full experience, you need to be on a smartphone. Scan the
          code above or visit <a href={pageUrl}>{pageUrl}</a> in your mobile
          browser.
        </p>
      </div>
    </div>
  )
};

export default ErrorScreenDesktop;
