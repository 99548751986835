// import Config from './config'
import React from 'react'
import { observer, Provider } from 'mobx-react'
import { isMobileOnly, isIOS, isMobileSafari, osVersion } from 'react-device-detect';
import RootStore from './state/RootStore'
import ARLoader from './scripts/ARLoader'
import AssetLoader from './scripts/AssetLoader'
import Homepage from './components/Homepage'
import LandingExperience from './components/LandingExperience'
import Div100vh from 'react-div-100vh'

import BasketballExperience from './components/BasketballExperience'
import FootballExperience from './components/FootballExperience'
import RacingExperience from './components/RacingExperience'
import FanLensExperience from './components/FanLensExperience'
import HboPropExperience from './components/HboPropExperience'
import CurbsideGaExperience from './components/CurbsideGaExperience'

import Config from './config'
import Data from './data/app'

import './App.scss'
// import './LoadingScreen.scss'
// import './components/Homepage.scss'

import ErrorScreen from './components/ErrorScreen'
import ErrorPage from './components/homepage/ErrorPage'
import ErrorScreenDesktop from './components/hboprop/ErrorScreenDesktop'
import ErrorScreenDesktopCurbsideGA from './components/curbsidega/ErrorScreenDesktop'

import GTM from "./scripts/GTM";

// @observer

class App extends React.Component {
  state = {
    arLoaded: false,
    experience: Config.get("experience"),
    loadHome: false
  };

  componentDidMount = () => {
    document.addEventListener('scroll', (e) => {
      e.preventDefault()
    })
    if (window.location.pathname === '/experience') {
      this.setState({ loadHome: true })
      this.changeView('')
    }
    else if (window.location.pathname === '/fanframe') {
      this.setState({
        loadHome: false,
        experience: 'fanframe'
      })
    }
    else if (window.location.pathname === '/fanframe/') {
      this.setState({
        loadHome: false,
        experience: 'fanframe'
      })
    }
    else if (window.location.pathname === '/hbomaxselfie') {
      this.setState({
        loadHome: false,
        experience: 'hbomaxselfie'
      })
    }
    else if (window.location.pathname === '/remote') {
      this.setState({
        loadHome: false,
        experience: 'curbsidega'
      })
    }
    else {
      if(this.state.experience !== ''){
        this.homepageClick()
      }
      else if(this.state.experience === ''){
        this.setState({ loadHome: true })
      }
    }

    AssetLoader.preload(Data.assets.ui);
  }

  homepageClick = e => {
    GTM.trackEvent("Open Camera", "Experience")
    ARLoader.load().then(() => {
      this.setState({ arLoaded: true })
    })
  }

  changeView = view => {
    this.setState({
      experience: view
    })
  }

  render() {
    if (isMobileOnly) {
      if (isIOS && isMobileSafari && parseInt(osVersion) < 11) {
        return <ErrorScreen />;
      }
      return (
        <Provider
          experience={RootStore.experience}
          racing={RootStore.racing}
          football={RootStore.football}
          basketball={RootStore.basketball}
          fanlens={RootStore.fanlens}
          hboprop={RootStore.hboprop}
          curbsidega={RootStore.curbsidega}
        >
          {/* <Div100vh> */}
            <div className="app">
              {!this.state.arLoaded && this.state.loadHome && <Homepage click={this.homepageClick} />}
              {this.state.arLoaded && this.state.experience === '' && <LandingExperience change={this.changeView}/>}
              {this.state.arLoaded && this.state.experience === 'racing' && <RacingExperience/>}
              {this.state.arLoaded && this.state.experience === 'basketball' && <BasketballExperience/>}
              {this.state.arLoaded && this.state.experience === 'football' && <FootballExperience/>}
              {this.state.experience === 'fanframe' && <FanLensExperience />}
              {this.state.experience === 'hbomaxselfie' && <HboPropExperience />}
              {this.state.experience === 'curbsidega' && <CurbsideGaExperience />}
            </div>
          {/* </Div100vh> */}
        </Provider>
      );
    }

    // Desktop error screens
    if (this.state.experience === 'hbomaxselfie') {
      return <ErrorScreenDesktop />
    }
    if (this.state.experience === 'curbsidega') {
      return <ErrorScreenDesktopCurbsideGA />
    }
    return <ErrorPage />;
  }
}

export default App;
