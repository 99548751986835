import React from 'react'
import styled from 'styled-components'

import ImageArrowLeft from '../../assets/images/hboprop/arrow-left.png'

class ButtonNoBackground extends React.Component {
  state = {}
  render = () => {
    const {
      style,
      onClick,
      children,
    } = this.props

    return (
      <Button
        onClick={onClick}
        style={style}
      >
        {children}
      </Button>
    )
  }
}

const Button = styled.button`
  position: relative;
  display: block;
  padding: 15px;
  font-family: 'ATT Aleck Sans';
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: none;
  border: none;
  outline: none;
`
export default ButtonNoBackground
