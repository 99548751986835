import {observable, action} from 'mobx'

class CurbisdeGaStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable screen = 'remote'
  @observable selectedVoiceCommand = null
  @observable voiceCommandsSeen = []
  @observable speechRecognitionActive = false
  @observable speechRecognitionListen = false
  @observable showTooltipGettingStarted = false
  @observable showTooltipChooseExperience = false
  @observable showTooltipSelectVoiceCommand = false
  @observable showTooltipSpeechInstructions = false
  @observable showTooltipSpeechCommands = false

  @action setScreen = (screen) => {
    console.log('[CurbisdeGaStore] setScreen:',screen);
    this.screen = screen
  }
  @action setSelectedVoiceCommand = (vc) => {
    console.log('[CurbisdeGaStore] setSelectedVoiceCommand:',vc);
    this.selectedVoiceCommand = vc
  }
  @action addVoiceCommandSeen = (id) => {
    console.log('[CurbisdeGaStore] addVoiceCommandSeen:',id);
    this.voiceCommandsSeen = [...this.voiceCommandsSeen, id];
  }
  @action setSpeechRecognitionActive = (bool) => {
    console.log('[CurbisdeGaStore] setSpeechRecognitionActive:',bool);
    this.speechRecognitionActive = bool;
  }
  @action setSpeechRecognitionListen = (bool) => {
    console.log('[CurbisdeGaStore] setSpeechRecognitionListen:',bool);
    this.speechRecognitionListen = bool;
  }
  @action setShowTooltipGettingStarted = (bool) => {
    console.log('[CurbisdeGaStore] setShowTooltipGettingStarted:',bool);
    this.showTooltipGettingStarted = bool;
  }
  @action setShowTooltipChooseExperience = (bool) => {
    console.log('[CurbisdeGaStore] setShowTooltipChooseExperience:',bool);
    this.showTooltipChooseExperience = bool;
  }
  @action setShowTooltipSelectVoiceCommand = (bool) => {
    console.log('[CurbisdeGaStore] setShowTooltipSelectVoiceCommand:',bool);
    this.showTooltipSelectVoiceCommand = bool;
  }
  @action setShowTooltipSpeechInstructions = (bool) => {
    console.log('[CurbisdeGaStore] setShowTooltipSpeechInstructions:',bool);
    this.showTooltipSpeechInstructions = bool;
  }
  @action setShowTooltipSpeechCommands = (bool) => {
    console.log('[CurbisdeGaStore] setShowTooltipSpeechCommands:',bool);
    this.showTooltipSpeechCommands = bool;
  }
}

export default CurbisdeGaStore
