import React from 'react'
import styled from 'styled-components'

import IconFlipCamera from '../../assets/images/hboprop/flipCamera.png'

class ButtonSwitchCamera extends React.Component {
  state = {}

  render() {
    const {
      onClick,
    } = this.props;

    return (
      <Container className='btn-switch-camera'
        onClick={onClick}
      >
        <Image src={IconFlipCamera} />
      </Container>
    )
  }
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  overflow: hidden;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
`
const Image = styled.img`
  width: 44px;
  height: auto;
`

export default ButtonSwitchCamera
