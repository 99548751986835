import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import './Tooltip.scss'

Tooltip.defaultProps = {
  isVisible: false,
  className: "",
  positionBottom: "153px",
  children: null,
};

export default function Tooltip(props) {
  const {
    isVisible,
    className,
    positionBottom,
    children,
  } = props;

  const [wasVisible, setWasVisible] = useState(false);
  const [classNames, setClassNames] = useState("");

  useEffect(() => {
    if (isVisible) {
      setClassNames(" transition-in");
    }
    else if(wasVisible) {
      setClassNames(" transition-out visible");
    }

    setWasVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (classNames == " transition-in") {
      setTimeout(() => {
        setClassNames(" transition-in visible");
      }, 33);
    }
    if (classNames == " transition-out visible") {
      setTimeout(() => {
        setClassNames(" transition-out");
      }, 33);
    }
  }, [classNames])

  return (
    <div className={"tooltip-container "+(isVisible ? " visible" : "")}
      style={{ bottom: positionBottom }}>
      <div className={"tooltip "+className+classNames}>
        {children}
      </div>
    </div>
  );
}
