
import React from "react"

export default class CloseButton extends React.Component {

  constructor () {
    super()

    this.streakBar = React.createRef()
    this.streakMultiplier = React.createRef()
    this.streakFlame = React.createRef()

  }

  render () {
    return (
      <svg viewBox="0 0 210.7 45.8">
        <defs>
          <clipPath id="clip-bar">
            <rect x="47.6" width={this.props.streakPercent * 122.8} height="39.71"/>
          </clipPath>
          <linearGradient id="linear-gradient" x1="50.77" y1="20.85" x2="166.61" y2="20.85" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#e9605b"/>
            <stop offset="1" stopColor="#f9a81a"/>
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="21.76" y1="8.95" x2="21.76" y2="33.36" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#f9a81a"/>
            <stop offset="0.99" stopColor="#e9605b"/>
          </linearGradient>
        </defs>
        <title>Streak-Bar</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <circle cx="23.8" cy="24.2" r="21.6" style={{"fill" : "#010101", "opacity": 0.1 }} />
              <path d="M46.1,7.6a28.9,28.9,0,0,1,6.4,18.1,28.5,28.5,0,0,1-6.4,18.1h108a18.1,18.1,0,0,0,18.1-18.1V7.6Z" style={{"fill": "#010101", "opacity": 0.1 }}/>
              <path d="M167.5,21.6A13.5,13.5,0,0,1,154,35.1H50.8a39.9,39.9,0,0,0,2.3-13.5A40.3,40.3,0,0,0,50.8,8.1H167.5Z" style={{"fill": "#fff" }}/>
              <circle cx="21.8" cy="21.6" r="19.3" style={{"fill": "#fff" }}/>
              <path id="Progress-Bar" d="M50.8,6.3H164.6l2,16.9-8.1,12.2H50.8s3.7-7.8,3.7-13.8S50.8,6.3,50.8,6.3Z" clipPath="url(#clip-bar)" style={{"fill": "url(#linear-gradient)"}}/>
              {/* <rect id="Progress-Mask" x="47.6" width="122.6" height="39.71" style={{"fill": "#70bf44"}}/> */}
              <path d="M21.6,43.2A21.6,21.6,0,1,1,43.2,21.6,21.6,21.6,0,0,1,21.6,43.2Zm0-36.8a15.3,15.3,0,0,0,0,30.5,15.3,15.3,0,1,0,0-30.5Z" style={{"fill": "#010101"}}/>
              <path d="M21.6,33.5a9.2,9.2,0,0,0-2.3-.9,7,7,0,0,1-3.7-7.4,3.7,3.7,0,0,1,1.5-2.8c0,1.1.1,2.5,1.1,3a2,2,0,0,0,2.5-.6,3.5,3.5,0,0,0,.9-2.9c-.2-1.3-1.1-2.3-1.5-3.5a5.7,5.7,0,0,1,.6-4.6A6.5,6.5,0,0,1,24.6,11a5.1,5.1,0,0,0,.6,4.8c1.1,1.6,3.1,2.4,4.5,3.8A8.2,8.2,0,0,1,32,27.2a8.9,8.9,0,0,1-5.1,6.1c-.7.3-1.6.5-2.4.9a22.5,22.5,0,0,1-2.1,1.2C22.3,34.7,22.3,34,21.6,33.5Z" style={{"fill": "#010101", "opacity": 0.1 }}/>
              <path id="Streak-Bar" ref={this.streakBar} d="M50.8,6.5H164.6l2,16.9-8.1,12.3H50.8s3.7-7.9,3.7-13.9S50.8,6.5,50.8,6.5Z" style={{"fill": "#e85f5b", "display": this.props.streakVisible ? 'block' : 'none'}}/>
              <path d="M44.1,3.5a28.9,28.9,0,0,1,6.4,18.1,28.5,28.5,0,0,1-6.4,18.1H152a18.1,18.1,0,0,0,18.1-18.1V3.5ZM163.8,21.6a11.9,11.9,0,0,1-11.9,12H54.5a34.4,34.4,0,0,0,2.1-12A33.5,33.5,0,0,0,54.5,9.7H163.8Z" style={{"fill": "#010101"}}/>
              <path d="M19.5,31.5a8.8,8.8,0,0,0-2.2-1c-2.8-1.1-4.2-4.4-3.8-7.3A4.3,4.3,0,0,1,15,20.3c0,1.2.1,2.5,1.1,3.1a2.2,2.2,0,0,0,2.5-.7,3.1,3.1,0,0,0,.9-2.9c-.2-1.3-1-2.3-1.4-3.5a5,5,0,0,1,.6-4.6,6.7,6.7,0,0,1,3.8-2.8,5.4,5.4,0,0,0,.6,4.8c1.1,1.6,3.1,2.5,4.5,3.8a8.2,8.2,0,0,1,2.3,7.6,8.6,8.6,0,0,1-5,6.1l-2.4.9a19.3,19.3,0,0,0-2.1,1.3A2.3,2.3,0,0,0,19.5,31.5Z" style={{"fill": "#010101"}}/>
              <path id="Streak-Flame" ref={this.streakFlame} d="M19.5,31.5a8.8,8.8,0,0,0-2.2-1c-2.8-1.1-4.2-4.4-3.8-7.3A4.3,4.3,0,0,1,15,20.3c0,1.2.1,2.5,1.1,3.1a2.2,2.2,0,0,0,2.5-.7,3.1,3.1,0,0,0,.9-2.9c-.2-1.3-1-2.3-1.4-3.5a5,5,0,0,1,.6-4.6,6.7,6.7,0,0,1,3.8-2.8,5.4,5.4,0,0,0,.6,4.8c1.1,1.6,3.1,2.5,4.5,3.8a8.2,8.2,0,0,1,2.3,7.6,8.6,8.6,0,0,1-5,6.1l-2.4.9a19.3,19.3,0,0,0-2.1,1.3A2.3,2.3,0,0,0,19.5,31.5Z" style={{"fill": "url(#linear-gradient-2)", "display": this.props.streakVisible ? 'block' : 'none'}}/>
              <g id="Streak-Multiplier" ref={this.streakMultiplier} transform="translate(-22,0)" style={{"display": this.props.streakVisible ? 'block' : 'none'}}>
                <circle cx="195.2" cy="24.2" r="16.5" style={{"fill": "#010101", "opacity": 0.1 }}/>
                <g>
                  <circle cx="192.8" cy="20.6" r="14.5" style={{"fill": "#fff" }}/>
                  <path d="M192.4,39.1a18.3,18.3,0,1,1,18.3-18.3A18.3,18.3,0,0,1,192.4,39.1Zm0-30.7a12.5,12.5,0,1,0,12.5,12.4A12.5,12.5,0,0,0,192.4,8.4Z" style={{"fill": "#010101"}}/>
                </g>
                <g>
                  <path d="M185.9,23.7l-2.1-3.1h2l1.1,1.8,1.2-1.8h1.8l-2,3,2.2,3.2h-2l-1.3-2-1.2,2h-1.9Z" style={{"fill": "#010101"}}/>
                  <path d="M190.7,26.8V24.9a5.9,5.9,0,0,1,.4-2,4.5,4.5,0,0,1,1.1-1.7,9.6,9.6,0,0,1,2.1-1.6l1.5-.9.8-.8a1.9,1.9,0,0,0,.2-.8,1.7,1.7,0,0,0-.5-1.2,2.2,2.2,0,0,0-1.3-.4,5.3,5.3,0,0,0-3,1.2l-1.1-2,2.2-1.2a7.8,7.8,0,0,1,2.2-.4,4.4,4.4,0,0,1,2.3.6,2.8,2.8,0,0,1,1.5,1.3,3.3,3.3,0,0,1,.6,1.9,4.1,4.1,0,0,1-.8,2.4,14.5,14.5,0,0,1-3,2.2,5.2,5.2,0,0,0-1.7,1.3,2.5,2.5,0,0,0-.5,1.6h6v2.4Z" style={{"fill": "#010101"}}/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>)
  }
}
