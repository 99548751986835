import React, { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import Scrollbar from 'react-scrollbars-custom'
import styled from 'styled-components'
import './CustomScrollbar.scss'

import Tooltip from './Tooltip'

TooltipSelectVoiceCommand.defaultProps = {
  isVisible: false,
  voiceCommands: [],
  onSelectVoiceCommand: null,
};

function TooltipSelectVoiceCommand(props) {
  const {
    isVisible,
    voiceCommands,
    onSelectVoiceCommand,
    curbsidega,
  } = props;

  const scrollbar = useRef(null)
  useEffect(() => {
    if(isVisible === true && scrollbar){
      scrollbar.current.scrollToTop()
    }
  },[isVisible])

  return (
    <Tooltip isVisible={isVisible} className="voicecommand">
      <div className="title">
        Tap a voice command:
      </div>
      <Scrollbar ref={scrollbar}
        noDefaultStyles={true} 
        minimalThumbYSize={80}
        maximalThumbYSize={80}
        style={{ width: 260, height: 250 }}>
        <div className="buttons scrollable">
          {voiceCommands.map((vc, index) => {

            return (
              <React.Fragment key={"vc-"+index}>
                <button
                  className={(curbsidega.voiceCommandsSeen.includes(vc.id) ? ' seen' : '')}
                  onClick={() => { onSelectVoiceCommand(vc) }}
                >
                  {vc.text}
                </button>
              </React.Fragment>
            );
          })}
        </div>
      </Scrollbar>
      <div className="arrow"></div>
    </Tooltip>
  );
}
export default inject('curbsidega')(observer(TooltipSelectVoiceCommand))
