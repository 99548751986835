import React from 'react'
import styled from 'styled-components'
import SelectorSubMenu from '../fanlens/SelectorSubMenu'
import ColorSelectorCircle from '../../assets/images/fanlens/color_select_circle.png'

// Whole colors
import cream from '../../assets/images/fanlens/colors/color_cream_whole.png'
import black from '../../assets/images/fanlens/colors/color_black_whole.png'
import white from '../../assets/images/fanlens/colors/color_white_whole.png'
import darkred from '../../assets/images/fanlens/colors/color_darkred_whole.png'
import red from '../../assets/images/fanlens/colors/color_red_whole.png'
import orange from '../../assets/images/fanlens/colors/color_orange_whole.png'
import yellow from '../../assets/images/fanlens/colors/color_yellow_whole.png'
import lightgreen from '../../assets/images/fanlens/colors/color_lightgreen_whole.png'
import darkgreen from '../../assets/images/fanlens/colors/color_darkgreen_whole.png'
import turquoise from '../../assets/images/fanlens/colors/color_turquoise_whole.png'
import lightblue from '../../assets/images/fanlens/colors/color_lightblue_whole.png'
import navyblue from '../../assets/images/fanlens/colors/color_navyblue_whole.png'
import purple from '../../assets/images/fanlens/colors/color_purple_whole.png'

// Half colors
import creamL from '../../assets/images/fanlens/colors/color_cream_left.png'
import creamR from '../../assets/images/fanlens/colors/color_cream_right.png'
import blackL from '../../assets/images/fanlens/colors/color_black_left.png'
import blackR from '../../assets/images/fanlens/colors/color_black_right.png'
import whiteL from '../../assets/images/fanlens/colors/color_white_left.png'
import whiteR from '../../assets/images/fanlens/colors/color_white_right.png'
import darkredL from '../../assets/images/fanlens/colors/color_darkred_left.png'
import darkredR from '../../assets/images/fanlens/colors/color_darkred_right.png'
import redL from '../../assets/images/fanlens/colors/color_red_left.png'
import redR from '../../assets/images/fanlens/colors/color_red_right.png'
import orangeL from '../../assets/images/fanlens/colors/color_orange_left.png'
import orangeR from '../../assets/images/fanlens/colors/color_orange_right.png'
import yellowL from '../../assets/images/fanlens/colors/color_yellow_left.png'
import yellowR from '../../assets/images/fanlens/colors/color_yellow_right.png'
import lightgreenL from '../../assets/images/fanlens/colors/color_lightgreen_left.png'
import lightgreenR from '../../assets/images/fanlens/colors/color_lightgreen_right.png'
import darkgreenL from '../../assets/images/fanlens/colors/color_darkgreen_left.png'
import darkgreenR from '../../assets/images/fanlens/colors/color_darkgreen_right.png'
import turquoiseL from '../../assets/images/fanlens/colors/color_turquoise_left.png'
import turquoiseR from '../../assets/images/fanlens/colors/color_turquoise_right.png'
import lightblueL from '../../assets/images/fanlens/colors/color_lightblue_left.png'
import lightblueR from '../../assets/images/fanlens/colors/color_lightblue_right.png'
import navyblueL from '../../assets/images/fanlens/colors/color_navyblue_left.png'
import navyblueR from '../../assets/images/fanlens/colors/color_navyblue_right.png'
import purpleL from '../../assets/images/fanlens/colors/color_purple_left.png'
import purpleR from '../../assets/images/fanlens/colors/color_purple_right.png'

const colorWhole = [cream, black, white, darkred, red, orange, yellow, lightgreen, darkgreen, turquoise, lightblue, navyblue, purple];
const colorLeft = [creamL, blackL, whiteL, darkredL, redL, orangeL, yellowL, lightgreenL, darkgreenL, turquoiseL, lightblueL, navyblueL, purpleL];
const colorRight = [creamR, blackR, whiteR, darkredR, redR, orangeR, yellowR, lightgreenR, darkgreenR, turquoiseR, lightblueR, navyblueR, purpleR];

class ColorSelector extends React.Component {
  state = {
    nextColorSelection: 1,
  }

  onColorSelected(index) {
    if (this.state.nextColorSelection == 1) {
      if(index == this.props.color2) {
        this.props.onColor1Change(this.props.color2);
        this.props.onColor2Change(this.props.color1);
        this.setState({ nextColorSelection: 2 });
      }
      else {
        this.props.onColor1Change(index);
        this.setState({ nextColorSelection: 2 })
      }
    }
    if (this.state.nextColorSelection == 2) {
      if(index == this.props.color1) {
        this.props.onColor1Change(this.props.color2);
        this.props.onColor2Change(this.props.color1);
        this.setState({ nextColorSelection: 1 });
      }
      else {
        this.props.onColor2Change(index);
        this.setState({ nextColorSelection: 1 })
      }
    }
  }

  render = () => (
    <Container style={window.innerWidth < 375 ? ContainerSmall : {}} className='color-selector'>
      <ContainerInner>
        <SelectorSubMenu open={this.props.menuOpen}>
          {colorWhole.map((color, index) => {
            let isSelected = (this.props.color1 == index || this.props.color2 == index);
            return (
              <MenuColorItem onClick={() => {this.onColorSelected(index)}} key={'color_'+index}>
                {isSelected &&
                  <MenuColorImageHighlight />
                }
                {isSelected && this.props.color1 == index &&
                  <MenuColorImageNumber>1</MenuColorImageNumber>
                }
                {isSelected && this.props.color2 == index &&
                  <MenuColorImageNumber>2</MenuColorImageNumber>
                }
                <MenuColorImage src={color} />
              </MenuColorItem>
            )
          })}
        </SelectorSubMenu>
        <div style={SelectorButtonContainer} onClick={this.props.onMenuToggle}>
            <SelectorButtonColors>
              <SelectorButtonColorHalf src={colorLeft[this.props.color1]} />
              <SelectorButtonColorHalf src={colorRight[this.props.color2]} />
            </SelectorButtonColors>
        </div>
      </ContainerInner>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-191px);
`
const ContainerSmall = {
  transform: 'translateX(-125px) scale(.75)',
}
const ContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const MenuColorItem = styled.div`
  position: relative;
  width: 100%;
  height: 34px;
  overflow: hidden;
`
const MenuColorImage = styled.img`
  position: absolute;
  top: -10px;
  left: 3px;
  height: 54px;
  width: 54px;
  pointer-events: none;
`
const MenuColorImageHighlight = styled.span`
  position: absolute;
  top: 2px;
  left: 15px;
  height: 28px;
  width: 28px;
  border: 1px solid rgba(255, 255, 255, .75);
  border-radius: 50%;
`
const MenuColorImageNumber = styled.span`
  font-size: 12px;
  left: 4px;
  position: absolute;
  top: 12px;
  font-weight: bold;
`

const SelectorButtonContainer = {
  backgroundImage: `url(${ColorSelectorCircle})`,
  backgroundSize: '84px 84px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '70px',
  width: '70px',
  left: '5px',
  top: '-5px',
  position:'relative',
  overflow: 'hidden'
}
const SelectorButtonColors = styled.div`
  position: absolute;
  top: 13.75px;
  left: 14px;
  height: 44px;
  width: 41px;
`
const SelectorButtonColorHalf = styled.img`
  height: 40px;
  width: 20px;
  pointer-events: none;
`

export default ColorSelector
