import React, { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  BrowserClient,
  BrowserMicrophone,
  Segment,
} from "@speechly/browser-client";

SpeechRecognition.defaultProps = {
  initialize: false,
  onInitSuccess: null,
  onInitFailure: null,
  onIntent: null,
};

function SpeechRecognition(props) {
  const {
    initialize,
    onInitSuccess,
    onInitFailure,
    onIntent,
    curbsidega,
  } = props;

  const client = useRef(null);
  const microphone = useRef(null);
  const connected = useRef(false);
  const [isRecording, setIsRecording] = useState(false);

  const init = async () => {
    console.log('[SpeechRecognition] init ^2.4.2');

    try {
      client.current = new BrowserClient({
        appId: 'fdd3cbd9-b59c-44fb-a863-91bccd8f6c81',
        language: 'en-US',
        debug: false,
        logSegments: false,
      });
      microphone.current = new BrowserMicrophone();
      await microphone.current.initialize();
      await client.current.attach(microphone.current.mediaStream);

      client.current.onSegmentChange(_onSegmentChange);
      connect();
    }
    catch (err) {
      onInitFailure(err);
    }
  }
  const connect = async () => {
    try {
      await client.current.initialize();
      connected.current = true;
      if (onInitSuccess) {
        onInitSuccess();
      }
    } catch (err) {
      if (onInitFailure) {
        onInitFailure(err);
      }
    }
  }
  const disconnect = async () => {
    try {
      await client.current.close();
      connected.current = false;
    } catch (err) {
    }
  }
  const startRecording = async () => {
    console.log('[SpeechRecognition] startRecording');
    if (connected.current) {
      try {
        await client.current.start();
        setIsRecording(true);
      } catch (err) {
        alert("Could not start recording - "+ err);
      }
    } else {
      curbsidega.setSpeechRecognitionListen(false);
      curbsidega.setScreen('remote');
      curbsidega.setShowTooltipChooseExperience(true);
    }
  }
  const stopRecording = async () => {
    console.log('[SpeechRecognition] stopRecording');
    if (isRecording) {
      try {
        await client.current.stop();
      } catch (err) {
        console.log('[SpeechRecognition] stopRecording', err);
      }
      setIsRecording(false);
    }
  }

  const _onSegmentChange = ({intent}) => {
    console.log('[SpeechRecognition] _onSegmentChange segment.intent = ', intent);
    if (intent.isFinal && onIntent) {
      onIntent(intent.intent);
    }
  }

  // [initialize]
  useEffect(() => {
    if (initialize) {
      init();
    }
  }, [initialize]);

  // [curbsidega.speechRecognitionListen]
  useEffect(() => {
    if (curbsidega.speechRecognitionListen) {
      if (!isRecording) {
        startRecording();
      }
    }
    else {
      if (isRecording) {
        stopRecording();
      }
    }
  }, [curbsidega.speechRecognitionListen]);

  // []
  useEffect(() => {
    return () => {
      if (connected.current) {
        disconnect();
      }
    }
  }, []);

  return (<></>);
}
export default inject('curbsidega')(observer(SpeechRecognition))
