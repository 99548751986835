import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Grid, ButtonBase, makeStyles, SwipeableDrawer } from '@material-ui/core'

import Animation from './homepage/Animation'
import CarouselCopy from './homepage/CarouselCopy'
import logo from '../assets/images/Logo.png'

import '../index.scss'

import GTM from '../scripts/GTM'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'ATT Aleck Sans',
    color: 'white',
    margin: '0',
    padding: '0'
  }, 
  margin: {
    margin: theme.spacing(1)
  },
  text: {
    fontFamily: 'ATT Aleck Sans',
    letterSpacing: '3px',
    fontWeight: 'bold',
    fontSize: '.9rem',
    color: '#0a71ae'
  },
  rippleVisible: {
    borderRadius: '30px'
  }
}))

const Homepage = props => {
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)
  const [cookieOpen, setCookieOpen] = useState(true)
  const [modalClicked, setModalClick] = useState(false)
  const [cookies, setCookie] = useCookies(['accept'])
  const [loading, setLoading] = useState(false)

  GTM.trackPageView('Landing Page')

  useEffect(() => {
    if (cookies.accept !== undefined) {
      setCookieOpen(false);
    }
  }, [cookies.accept])

  useEffect(() => {
    if (localStorage.getItem('dev')) {
      props.click()
    }
  }, [])

  const renderAnime = () => {
    return <Animation className="animation" />
  }

  return (
    <div>
      <Grid
        container
        justify="flex-start"
        style={{ position: 'absolute', zIndex: 0, top: 0, bottom: 0 }}
        className="snow-bg"
      >
      </Grid>
      <Grid
        className={`${classes.root} home`}
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        style={{ height: '100%', width: '100%' }}
      >
        <img
          src={logo}
          alt="Find Your Game"
          className="logo"
        />
        <Grid
          container
          style={{ height: '100%', marginTop: '-20px' }}
          className="content"
        >
          {renderAnime()}
          <p style={{ color: '#fff', textAlign: 'center', padding: '0 50px', margin: '-10px auto', fontWeight: '300' }}>Use your camera to scan <strong>HEY, CAN'T STOP, DREAM TEAM</strong> or <strong>FOR THE WIN.</strong></p>
          <ButtonBase className='button' onClick={props.click}>Let's play</ButtonBase>
          <p className="allow-camera-access" style={{ color: '#fff', textAlign: 'center', margin: '-10px auto 0', fontSize: '0.8rem' }}>Allow camera access to get in the game. <br/> Not working? You may need to adjust browser settings.</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default Homepage
