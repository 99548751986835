import { observable, computed, action } from "mobx";

const INITIAL_TIME = 60;
const TIME_VALUE = 5;
const POINT_VALUE = 3;
const STREAK_TOTAL = 3

class BasketballStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable score = 0;
  @observable highscore = 0

  @observable time = INITIAL_TIME;
  @observable streak = 0;
  @observable isStreak = false;
  @observable isPaused = true;

  @computed get streakPercent () {
    return this.streak / STREAK_TOTAL
  }

  @computed get formattedTime () {
    return this.time > 9 ? this.time : `0${this.time}`
  }
  
  @computed get formattedScore () {
    return this.score.toLocaleString('en')
  }

  @computed get formattedHighscore () {
    return this.highscore.toLocaleString('en')
  }

  @action checkHighscore() {
    this.highscore = localStorage.getItem('basketball_score') !== null ? parseInt(localStorage.getItem("basketball_score")) : 0
    if (this.score > this.highscore) {
      this.highscore = this.score;
      localStorage.setItem("basketball_score", this.score);
    }
  }

  @action setView = view => {
    this.view = view
  }

  @action addPoints = () => {
    this.score += this.isStreak ? POINT_VALUE * 2 : POINT_VALUE;
  };

  @action resetStreak = () => {
    this.streak = 0
    this.setIsStreak(false)
  }

  @action setStreak = () => {
    if(this.streak < 3){
      this.streak++
    }
    if(this.streak === 3){
      this.setIsStreak(true)
    }
  }

  @action addTime = () => {
    this.time += TIME_VALUE;
  };

  @action setTime = (time) => {
    this.time = time;
  }

  @action reset = () => {
    this.score = 0;
    this.time = INITIAL_TIME;
    this.isStreak = false;
    this.streak = 0;
  };

  @action setIsStreak = isStreak => {
    this.isStreak = isStreak;
  };

  @action setIsPaused = isPaused => {
    this.isPaused = isPaused;
  };
}

export default BasketballStore;
