import React from 'react'
import styled from 'styled-components'
import SelectorSubMenu from '../fanlens/SelectorSubMenu'
import stickerSelectorCircle from '../../assets/images/fanlens/sticker_select_circle.png'

// Menu stickers
import none from '../../assets/images/fanlens/stickers/sticker_nosticker.png'
import soccerball from '../../assets/images/fanlens/stickers/sticker_soccerball_sm.png'
import numberOne from '../../assets/images/fanlens/stickers/sticker_number1_sm.png'
import basketball from '../../assets/images/fanlens/stickers/sticker_basketball_sm.png'
import football from '../../assets/images/fanlens/stickers/sticker_football_sm.png'
import baseball from '../../assets/images/fanlens/stickers/sticker_baseball_sm.png'
import puck from '../../assets/images/fanlens/stickers/sticker_hockey_sm.png'

let stickers = [none, numberOne, puck, baseball, soccerball, basketball, football];


class StickerSelector extends React.Component {
  state = {
  }

  render = () => (
    <div className='sticker-selector' style={window.innerWidth <= 374 ? mainContainerSm : mainContainer}>
      <InnerContainer>
        <SelectorSubMenu
          open={this.props.menuOpen}>
          {stickers.map((sticker, index) => {
            let isSelected = (this.props.sticker == index);
            return (
              <StickerItemContainer onClick={() => { this.props.onStickerChange(index) }} key={'sticker_'+index}>
                {isSelected &&
                  <StickerItemActive />
                }
                <MenuStickerItem src={sticker} />
              </StickerItemContainer>
            )
          })}
        </SelectorSubMenu>
        <StickerSelectorContainer onClick={this.props.onMenuToggle}>
            <SelectedSticker src={stickers[this.props.sticker]} />
        </StickerSelectorContainer>
      </InnerContainer>
    </div>
  )
}

const mainContainer = {
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-124px)',
}
const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const StickerItemContainer = styled.div`
  position: relative;
  height: 42px;
  overflow: hidden;
`
const StickerItemActive = styled.span`
  position: absolute;
  height: 36px;
  width: 36px;
  left: 11px;
  top: 2px;
  border: 1px solid rgba(255, 255, 255, .75);
  border-radius: 50%;
`
const MenuStickerItem = styled.img`
  height: 54px;
  width: 54px;
  margin-top: -6px;
`
const StickerSelectorContainer = styled.div`
  background-image: url(${stickerSelectorCircle});
  background-size: 84px 84px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 70px;
  width: 70px;
  left: 5px;
  top: -5px;
  text-align: center;
  overflow: hidden;
`
const SelectedSticker = styled.img`
  position: relative;
  bottom: -2px;
  left: -2px;
  height: 64px;
  width: 64px;
  pointer-events: none;
`

// breakpoint styles
const mainContainerSm = {
  ...mainContainer,
  transform: 'translateX(45px) scale(.75)',
}

export default StickerSelector
