import React from 'react'
import Logo from '../assets/images/Logo.png'
import Hoop from '../assets/images/Hoop.png'
import { ButtonBase } from '@material-ui/core'

const button = false

export default class ErrorScreen extends React.Component {

  render = () => (
    <div className='ar-loading-screen error'>
      <img className='logo' src={Logo} alt='logo' />
      <img className='image' src={Hoop} alt='' />
      <h1>Whoops!</h1>
      <p>Unfortunately, you can’t unwrap holiday fun with your device. Talk to a sales rep to find your next phone.</p>
      { button && <ButtonBase className="button">Button</ButtonBase> }
    </div>
  )
}
