import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Animated } from "react-animated-css";
import "./video.css";

import Screen from "./Screen";

ScreenVideo.defaultProps = {
  isVisible: false,
  voiceCommands: [],
  voiceCommandScreen: "",
  videoElements: null,
  onDone: null,
};

export default function ScreenVideo(props) {
  const {
    isVisible,
    voiceCommands,
    voiceCommandScreen,
    videoElements,
    onDone,
  } = props;

  const [videoStyles, setVideoStyles] = useState({});
  const [videoFadeOut, setVideoFadeOut] = useState(true);
  const [videoOpacity, setVideoOpacity] = useState(0);

  const containerEl = useRef(null);
  const videoEls = useRef([]);
  const timeoutIdVideoDone = useRef(null);

  const play = () => {
    // Play the video
    let index = getVoiceCommandIndex(voiceCommandScreen);
    const videoEl = videoEls.current[index];

    videoEl.currentTime = 0.1;
    videoEl.play();

    setTimeout(() => setVideoOpacity(1), 3000);
  };

  const onVideoStart = (vcIndex) => {
    if (videoEls.current[vcIndex]) {
      let duration = videoEls.current[vcIndex].duration;
      let videoEndDurationOffset =
        voiceCommands[vcIndex].videoEndDurationOffset;

      if (!isNaN(duration)) {
        timeoutIdVideoDone.current = setTimeout(() => {
          timeoutIdVideoDone.current = null;
          onVideoEnd(vcIndex);
        }, (duration + videoEndDurationOffset) * 1000);
      }
    }
  };
  const onVideoPause = (vcIndex) => {
    if (timeoutIdVideoDone.current !== null) {
      clearTimeout(timeoutIdVideoDone.current);
      timeoutIdVideoDone.current = null;
    }
  };
  const onVideoEnd = (vcIndex) => {
    setVideoFadeOut(true);

    setTimeout(() => {
      if (videoEls.current[vcIndex]) {
        videoEls.current[vcIndex].pause();
      }
      setVideoOpacity(0)
      onDone();
    }, 600 + 100);
  };
  const onResize = () => {
    updateVideoWidthHeight();
  };

  const getVoiceCommandIndex = (screen) => {
    for (let i = 0; i < voiceCommands.length; i++) {
      if (voiceCommands[i].screen === voiceCommandScreen) {
        return i;
      }
    }
    return null;
  };
  const updateVideoVisibility = () => {
    for (let i = 0; i < voiceCommands.length; i++) {
      if (voiceCommands[i].screen == voiceCommandScreen) {
        if (videoEls.current[i]) {
          videoEls.current[i].style.opacity = 1;
        }
      } else {
        if (videoEls.current[i]) {
          videoEls.current[i].style.opacity = 0;
        }
      }
    }
  };
  const updateVideoWidthHeight = () => {
    if (!containerEl.current) return;
    let ind = getVoiceCommandIndex(voiceCommandScreen);

    if (ind != null) {
      let videoWidth = voiceCommands[ind].videoWidth;
      let videoHeight = voiceCommands[ind].videoHeight;
      let containerAspect =
        containerEl.current.clientWidth / containerEl.current.clientHeight;
      let videoAspect = videoWidth / videoHeight;

      if (containerAspect > videoAspect) {
        setVideoStyles({
          width: containerEl.current.clientWidth + "px",
          height: containerEl.current.clientWidth / videoAspect + "px",
        });
      } else {
        setVideoStyles({
          width: containerEl.current.clientHeight * videoAspect + "px",
          height: containerEl.current.clientHeight + "px",
        });
      }
    }
  };
  const getVideoHTML = () => {
    let html = ``;

    for (var i = 0; i < voiceCommands.length; i++) {
      html += `
        <video class="curbsidega has-fade"
          playsinline
          preload="metadata"
        >
          <source src="${voiceCommands[i].videoPath}" type="video/mp4" />
        </video>
      `;
    }
    return html;
  };

  useEffect(() => {
    if (isVisible) {
      // setTimeout(() => {
      setVideoFadeOut(false);
      play();
      // }, 600);
    } else {
      // Pause the video
      for (let i = 0; i < videoEls.current.length; i++) {
        if (!videoEls.current[i].paused) {
          videoEls.current[i].pause();
        }
      }

      if (timeoutIdVideoDone.current !== null) {
        clearTimeout(timeoutIdVideoDone.current);
        timeoutIdVideoDone.current = null;
      }

      setVideoFadeOut(true);

      setTimeout(() => {
        setVideoStyles({});

        setTimeout(() => {
          if (timeoutIdVideoDone.current !== null) {
            clearTimeout(timeoutIdVideoDone.current);
            timeoutIdVideoDone.current = null;
          }
        }, 100);
      }, 600);
    }
  }, [isVisible]);

  useEffect(() => {
    updateVideoVisibility();
    updateVideoWidthHeight();
  }, [voiceCommandScreen]);

  useEffect(() => {
    // Listeners
    window.addEventListener("resize", onResize);

    return () => {
      // Listeners
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const voiceCommandIndex = getVoiceCommandIndex(voiceCommandScreen);
  let videoLegal = "";
  if (voiceCommandIndex !== null) {
    videoLegal = voiceCommands[voiceCommandIndex].videoLegal;
  }

  return (
    <Screen isVisible={isVisible}>
      <Container
        style={{ opacity: videoOpacity }}
        // style={{ opacity: 0 }}
        ref={(ref) => {
          if (ref && !containerEl.current) {
            containerEl.current = ref;
            updateVideoWidthHeight();
          }
        }}
      >
        <VideoContainer
          style={{
            ...videoStyles,
            ...(videoFadeOut ? styleVideoFadeOut : {}),
          }}
        >
          <VideoInner
            ref={(ref) => {
              if (ref && videoEls.current.length === 0) {
                videoEls.current = ref.children;
                videoElements(videoEls.current);
                updateVideoVisibility();

                for (let i = 0; i < videoEls.current.length; i++) {
                  videoEls.current[i].addEventListener("playing", () => {
                    onVideoStart(i);
                  });
                  videoEls.current[i].addEventListener("pause", () => {
                    onVideoPause(i);
                  });
                }
              }
            }}
            dangerouslySetInnerHTML={{ __html: getVideoHTML() }}
          />
        </VideoContainer>

        {videoLegal.length > 0 && (
          <VideoLegalText
            style={{
              ...(videoFadeOut ? styleVideoFadeOut : {}),
            }}
          >
            {videoLegal}
          </VideoLegalText>
        )}
      </Container>
    </Screen>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 115px);
  background-color: #000;
  zindex: 0;
`;
const VideoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 600ms ease-in-out;
`;
const styleVideoFadeOut = {
  opacity: "0",
};
const VideoInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const VideoLegalText = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  text-align: center;
  font-size: 11px;
  color: #fff;
  font-family: "ATT Aleck Cd";
  font-weight: 700;
  font-style: normal;
  transition: opacity 600ms ease-in-out;
`;
