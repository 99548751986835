import React from 'react';
import { Grid } from '@material-ui/core';
import errorImg from './errorCopy.png';
import './styles.css';

const ErrorPage = () => (
  <div>
    <Grid
      className="errorPage"
      container
      justify="center"
      alignItems="center"
      direction="column"
    >
      <div className="error-container">
        <img src={errorImg} alt="Game On" />
        <p>
          Just not from a desktop or tablet. You&apos;ll need a smart phone to
          unlock this webpage.
        </p>
      </div>
    </Grid>
  </div>
);

export default ErrorPage;
