import React from 'react'
import styled from 'styled-components'

import Overlay from './Overlay'
import ImageFingerIcon from '../../assets/images/hboprop/fingerIcon.png'

class OverlayPropInstructions extends React.Component {
  state = {}
  render = () => {
    const { isVisible } = this.props;

    return (
      <Overlay isVisible={isVisible}>
        <Container className='overlay-prop-instructions'>
          <Image src={ImageFingerIcon} />
          <Text>Pinch and zoom to adjust the prop</Text>
        </Container>
      </Overlay>
    )
  }
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0,0,0,0.55);
`
const Image = styled.img`
  position: relative;
  width: 120px;
  height: auto;
  margin-bottom: 10px;
`
const Text = styled.div`
  font-size: 18px;
  text-align: center;
  margin-bottom: 115px;
`

export default OverlayPropInstructions
