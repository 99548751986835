import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import ExperienceLoader from '../scripts/ExperienceLoader'
import Div100vh from 'react-div-100vh'

import LandingScreen from '../components/gameplay/racing/LandingScreen'
import HUDScreen from '../components/gameplay/racing/HUDScreen'
import GameOverScreen from '../components/gameplay/GameOverScreen'
import GamePausedScreen from '../components/gameplay/GamePausedScreen'
import Instructions from '../components/gameplay/racing/Instructions'
import RotateScreen from '../components/gameplay/RotateScreen'
import RootStore from "../state/RootStore";
const RacingStore = RootStore.racing;

import Config from '../config'

@inject('racing')
@observer
class RacingGame extends React.Component {
  state = {
    loaded: false,
    view: 'landing',
    rotate: false,
    indicator: false,
    indicatorAngle: 0,
    ended: false,
    score: 0,
    time: '1:00',
    progressPercent: 0,
  }

  componentDidMount = () => {
    this.updateInterval = null
    ExperienceLoader.load('Racing').then(this.onLoadModule)
    this.setOrientation()
    this.container.addEventListener('touchstart', this.onTouchHandler, true)
  }

  componentWillUnmount = () => {
    clearInterval(this.updateInterval)
    this.updateInterval = null
    this.unmounted = true
  }

  restart = () => {
    this.game.restart()
    this.setState({view: 'game', score: 0, ended: false, time: '1:00'})
  }

  onTouchHandler = e => {
    if (
      !e.target.classList.contains('reposition') &&
      !e.target.classList.contains('pause-button') &&
      this.game &&
      this.game.started &&
      this.game.clock.running
    ) {
      if (e.touches.length > 2) {
        return
      }
      let x = (e.touches[0].clientX / window.innerWidth) * 2 - 1
      // let y = - (e.touches[0].clientY / window.innerHeight) * 2 + 1
      if (x <= 0) {
        this.game.onSwipeDown()
      } else {
        this.game.onSwipeUp()
      }
    }
  }

  setOrientation = () => {
    if (window.innerHeight > window.innerWidth) {
      this.setMustRotate()
    }
    window.addEventListener(
      'orientationchange',
      () => {
        window.orientation === 0 ? this.setMustRotate() : this.unsetMustRotate()
      },
      false
    )
  }

  setMustRotate = () => {
    this.setState({rotate: true})
    if (this.state.view === 'game') {
      this.game.pause()
    }
  }

  unsetMustRotate = () => {
    this.setState({rotate: false})
    if (this.state.view === 'game') {
      this.game.unpause()
    }
  }

  onLoadModule = module => {
    this.game = new module.default()
    this.game.load().then(() => {
      this.game.init(this.canvas)
      this.setState({loaded: true})
      this.onExperienceLoad()
    })
  }

  onExperienceLoad = () => {
    this.updateInterval = setInterval(this.onUIUpdate, 100)
  }

  onUIUpdate = () => {
    let time = Math.round(this.game.time)

    if (this.game.ended === false && this.state.view !== 'paused') {
      this.props.racing.time = time
      this.props.racing.score = this.game.score
      this.setState({progressPercent: RacingStore.progressPercent})
    }

    if (this.state.ended === false && this.game.ended === true) {
      this.props.racing.time = 0
      this.props.racing.checkHighscore()
      this.setState({view: 'gameover', ended: true})
    }
  }

  closeHandler() {
    window.location.href = Config.get('url') + this.props.racing.endUrl
  }

  startGame() {
    if (this.updateInterval === null) {
    }
    this.game.start()
    this.game.unpause()
  }

  gameAction = (screen, action = null) => {
    if (this.state.loaded) {
      if (screen === 'rotate') {
        this.setState({
          view: 'landing'
        })
      }

      if (screen === 'landing') {
        this.setState({
          view: 'instructions'
        })
      }

      if (screen === 'instructions') {
        this.setState({
          view: 'game'
        })
        this.startGame()
      }

      if (screen === 'help') {
        if (action === 'close' || action === 'play') {
          this.setState({
            view: 'game'
          })
          this.game.unpause()
        }
      }

      if (screen === 'hud') {
        if (action === 'reset') {
          window.XR.XrController.recenter()
        }

        if (action === 'pause') {
          this.game.pause()
          this.setState({
            view: 'paused'
          })
        }
      }

      if (screen === 'paused') {
        if (action === 'restart') {
          this.restart()
        }
        if (action === 'quit') {
          window.location.href = Config.get('url') + this.props.racing.endUrl
        }
        if (action === 'help') {
          this.setState({
            view: 'help'
          })
        }
        if (action === 'cancel') {
          this.setState({
            view: 'game'
          })
          this.game.unpause()
        }
      }

      if (screen === 'gameover') {
        if (action === 'restart') {
          this.restart()
        }
        if (action === 'quit') {
          window.location.href = Config.get('url') + this.props.racing.endUrl
        }
      }
    }
  }

  render() {
    let GameStore = this.props.racing
    return (
      <Div100vh>
        <Container ref={r => (this.container = r)} className='racing-game'>
          <div
            className={`overlay
            ${
              this.state.view !== 'game' || this.state.rotate === true
                ? ''
                : 'hide'
            }
          `}
          ></div>
          {this.state.rotate === false && this.state.view === 'landing' && (
            <LandingScreen
              loaded={this.state.loaded}
              action={this.gameAction}
            />
          )}
          {this.state.rotate === false &&
            (this.state.view === 'instructions' ||
              this.state.view === 'help') && (
              <Instructions
                action={this.gameAction}
                showCloseButton={this.state.view === 'help'}
              />
            )}
          {this.state.rotate === false && this.state.view === 'game' && (
            <HUDScreen
              time={this.state.time}
              score={this.state.score}
              action={this.gameAction}
            />
          )}
          {this.state.rotate === false && this.state.view === 'paused' && (
            <GamePausedScreen action={this.gameAction} />
          )}
          {this.state.rotate === false && this.state.view === 'gameover' && (
            <GameOverScreen
              game={'racing'}
              score={GameStore.score}
              highscore={GameStore.highscore}
              scoreText={GameStore.scoreText}
              highscoreText={GameStore.highscoreText}
              action={this.gameAction}
            />
          )}
          {this.state.rotate === true && (
            <RotateScreen action={this.gameAction} />
          )}
        </Container>
      </Div100vh>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
export default RacingGame