import React from 'react'
import Rotate from '../../assets/images/RotateArt.png'

export default class RotateScreen extends React.Component {
  render = () => (
    <div className="rotate" onClick={() => { this.props.action('rotate') }}>
      <div className="content">
        <h1>Rotate <br /> <span>your screen</span></h1>
        <img src={Rotate} alt="rotate phone" />
        <h4>THIS EXPERIENCE REQUIRES YOUR DEVICE TO BE IN { window.orientation === 0 ? 'LANDSCAPE' : 'PORTRAIT' } MODE</h4>
      </div>
    </div>
  )
}