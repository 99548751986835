
import React from 'react'
import { ButtonBase } from '@material-ui/core'

const ScoreContent = (props) => {
  if (props.score === props.highscore) {
  return (
    <p>That's a new personal best!</p>
    )
  } else {
    if (props.game !== 'racing'){
      return (
        <p>Your personal best is {props.highscoreText}!</p>
      )
    } else {
      return (
        <p>Your personal best is {props.highscore} miles.</p>
      )
    }
  }
}

export default class GameOver extends React.Component {
  
  playAgain = () => {
    if (this.props.action) {
      this.props.action('gameover', 'restart')
    }
  }

  quit = () => {
    if (this.props.action) {
      this.props.action('gameover', 'quit')
    }
  }

  render () {
    return (
      <div className='game-over'>
        <h1>Game over</h1>
        <div className="score">
          { this.props.game !== 'racing' ? 
            <div className="column">
              <span>Your score</span>
              <h3>{this.props.scoreText}</h3>
            </div>
            :  
            <div className="column">
              <span>You drove</span>
              <h3>{this.props.score}</h3>
              <span>miles!</span>
            </div>
          }
          { this.props !== undefined && <ScoreContent {...this.props} /> }
        </div>
        <div className='buttons'>
          <ButtonBase className="button" onClick={this.playAgain}>Play Again</ButtonBase>
          <ButtonBase className="button" id="end" onClick={this.quit}>Quit</ButtonBase>
        </div>
      </div>
    )
  }
}
