import React from 'react'
import styled from 'styled-components'

import ImageArrowLeft from '../../assets/images/hboprop/arrow-left.png'

class ButtonRetake extends React.Component {
  state = {}
  render = () => {
    const {
      onClick,
    } = this.props

    return (
      <Button onClick={onClick}>
        <Icon src={ImageArrowLeft} /> Retake
      </Button>
    )
  }
}

const Button = styled.button`
  position: relative;
  display: block;
  padding: 15px 15px 15px 30px;
  font-family: 'ATT Aleck Sans';
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  background: none;
  border: none;
  outline: none;
`
const Icon = styled.img`
  position: absolute;
  top: 50%;
  left: 2px;
  width: 12px;
  height: auto;
  transform: translateY(-50%);
`

export default ButtonRetake
